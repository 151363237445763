import { Button } from '@mui/material'
import { createQuery } from 'api/query'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import { useShowControl } from 'hooks/useShowControl'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'

export function QueryControlAdd() {
  const navigate = useNavigate()
  const [isOpen, handleOpen, handleClose] = useShowControl()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (name: string) => {
    try {
      setIsLoading(true)
      const response = await createQuery({ name })
      navigate(`${routes.queryEdit}?id=${response.data.id}`)
      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Add Query
      </Button>

      <ModalWithTextInput
        value=""
        title="Create Query"
        label="Query name"
        errorText="Query name is required"
        buttonText="Create"
        isOpen={isOpen}
        handleClose={handleClose}
        onSubmit={handleSubmit}
        loading={isLoading}
      />
    </>
  )
}
