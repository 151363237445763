import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Stack } from '@mui/material'
import FormDatePicker from 'components/form-elements/FormDatePicker'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import { minButtonWidth, twoLettersStatesUSA } from 'const'
import { UIModal } from 'features/UI'
import moment from 'moment'
import { Moment } from 'moment/moment'
import React, { ChangeEvent, Fragment, useCallback, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { AccessCode, BaseAccessCode, EAccessCodeType } from 'types'
import { Nullish } from 'types/common.types'
import {
  formatDateAccessCode,
  formatDateDuration,
  getDateDiff,
  isDateSameOrAfter,
  parseDateDuration,
} from 'utils'
import {
  ManagerType,
  ManagerTypeOrder,
  ManagerViewDataByType,
  useOrganizationFormData,
} from '../../hooks/useOrganizationFormData'
import {
  organizationSingleNumbers,
  organizationTypes,
  typeOptions,
} from '../../models/AccessManagement.model'
import { formSettings, FormType } from './AccessCode.scheme'

interface Props {
  isOpen: boolean
  handleClose: () => void
  onSubmit: (code: BaseAccessCode) => void
  loading: boolean
  type: EAccessCodeType
  editedItem: Nullish<AccessCode>
}

const ModalContent = (props: Props) => {
  const { onSubmit, handleClose, loading, editedItem } = props

  const isUpdateForm = useMemo(() => {
    return !!editedItem
  }, [editedItem])

  const [codeType, setCodeType] = useState(props.type)
  const methods = useForm<FormType>(formSettings(codeType, editedItem))

  const { register, setValue, handleSubmit, trigger, watch } = methods
  const effectiveDateValue = watch('effectiveDate')

  const {
    managerIds,
    onChangeManagerId,
    managerOptions,
    organizationId,
    setOrganizationId,
    organizationsOptions,
    findOrganizationById,
    findUserInTreeById,
  } = useOrganizationFormData()

  const clearManagerFields = useCallback(
    (fields: ManagerType[]) => {
      fields.forEach((field) => {
        onChangeManagerId(0, field)
        setValue(`${field}FirstName`, '')
        setValue(`${field}LastName`, '')
        setValue(`${field}Email`, '')
      })
    },
    [onChangeManagerId, setValue],
  )

  const clearOrganizationFields = useCallback(() => {
    setOrganizationId(0)
    setValue('organizationName', '')
    setValue('organizationType', '')
    setValue('twoDigitsOrg', '')
  }, [setOrganizationId, setValue])

  const isDisabledCreateManager = useCallback(
    (managerType: ManagerType) => {
      const isSelectedManager = managerIds[managerType] !== 0

      if (managerType === ManagerType.owner) {
        return isSelectedManager || managerOptions[managerType].length > 1
      }

      return isSelectedManager
    },
    [managerIds, managerOptions],
  )

  const handleChangeOrganization = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newOrganizationId = Number(event.target.value)
      setOrganizationId(newOrganizationId)

      if (newOrganizationId === 0) {
        clearOrganizationFields()
      } else {
        const organizationData = findOrganizationById(newOrganizationId)
        if (organizationData) {
          setValue('organizationName', organizationData.name)
          setValue('organizationType', organizationData.type)
          setValue('twoDigitsOrg', organizationData.twoDigits)
        }
      }

      clearManagerFields([
        ManagerType.owner,
        ManagerType.regional,
        ManagerType.facility,
        ManagerType.supervisor,
      ])
    },
    [
      clearManagerFields,
      clearOrganizationFields,
      findOrganizationById,
      setOrganizationId,
      setValue,
    ],
  )

  const handleChangeManager = useCallback(
    (managerType: ManagerType, newId: number, shouldClearFieldsAfter: boolean) => {
      onChangeManagerId(newId, managerType)

      const typeIndex = ManagerTypeOrder.indexOf(managerType)
      const typesAfter = ManagerTypeOrder.slice(typeIndex + 1)

      if (newId === 0) {
        clearManagerFields([managerType])
      } else {
        const user = findUserInTreeById(newId)
        if (user) {
          setValue(`${managerType}FirstName`, user.firstname, { shouldValidate: true })
          setValue(`${managerType}LastName`, user.lastname, { shouldValidate: true })
          setValue(`${managerType}Email`, user.email, { shouldValidate: true })

          if (managerType === ManagerType.facility && user.managerId) {
            handleChangeManager(ManagerType.regional, user.managerId, false)
          }
        }
      }

      if (shouldClearFieldsAfter) {
        clearManagerFields(typesAfter)
      }
    },
    [clearManagerFields, findUserInTreeById, onChangeManagerId, setValue],
  )

  const handleChangeManagerFromSelect = useCallback(
    (managerType: ManagerType) => (event: ChangeEvent<HTMLInputElement>) => {
      const newId = Number(event.target.value)
      handleChangeManager(managerType, newId, true)
    },
    [handleChangeManager],
  )

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    const singleLetterOrg = organizationSingleNumbers.find(
      (it) => it.value === values.organizationType,
    )?.label as string

    let accessCode: BaseAccessCode = {
      codeType: codeType,
      reason: values.reason,
      effectiveDate: formatDateAccessCode(values.effectiveDate),
      expiryDate: formatDateAccessCode(values.expiryDate),
      specificState: values.state,
      specificUnitName: values.specificUnitName,
      specificTwoDigits: values.twoDigitsSpecific,
      duration: values.duration,
      numberOfUsers: Number(values.numberOfUsers),
    }

    if (isUpdateForm) {
      // Different models on backend for create and edit. Need to refactor after backend changes
      accessCode = {
        ...accessCode,
        organizationName: values.organizationName,
        organizationType: values.organizationType,
        twoDigitsOrg: values.twoDigitsOrg,
        singleLetterOrg,
        state: values.state,
        twoDigitsSpecific: values.twoDigitsSpecific,
      }
    }

    if (!isUpdateForm) {
      if (organizationId) {
        accessCode.organizationId = organizationId
      } else {
        accessCode = {
          ...accessCode,
          organizationName: values.organizationName,
          organizationType: values.organizationType,
          organizationTwoDigits: values.twoDigitsOrg,
          organizationSingleLetter: singleLetterOrg,
        }
      }

      ManagerTypeOrder.forEach((managerType) => {
        const prefix = ManagerViewDataByType[managerType].backendField as
          | 'owner'
          | 'regionalManager'
          | 'facilityManager'
          | 'supervisor'
        if (managerIds[managerType]) {
          accessCode[`${prefix}Id`] = managerIds[managerType]
        } else {
          if (values[`${managerType}FirstName`]) {
            accessCode[`${prefix}FirstName`] = values[`${managerType}FirstName`]
          }
          if (values[`${managerType}LastName`]) {
            accessCode[`${prefix}LastName`] = values[`${managerType}LastName`]
          }
          if (values[`${managerType}Email`]) {
            accessCode[`${prefix}Email`] = values[`${managerType}Email`]
          }
        }
      })
    }

    onSubmit(
      editedItem
        ? {
            ...accessCode,
            id: editedItem.id,
          }
        : accessCode,
    )
  }

  const handleChangeDuration = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const { years, months, days } = parseDateDuration(e.target.value)
      const newExpiryDate = moment(effectiveDateValue).add({
        years,
        months,
        days,
      })
      setValue('duration', e.target.value)
      setValue('expiryDate', newExpiryDate)
    },
    [effectiveDateValue, setValue],
  )

  const handleChangeExpiryDate = useCallback(
    (value: Moment | null) => {
      if (!value || !isDateSameOrAfter(value, effectiveDateValue)) {
        return
      }

      setValue('expiryDate', value)

      const diff = getDateDiff(effectiveDateValue, value)
      const duration = formatDateDuration(diff)
      setValue('duration', duration)
      trigger('duration')
    },
    [effectiveDateValue, setValue, trigger],
  )

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormSelect
              label="Type"
              name="type"
              options={typeOptions}
              required
              value={codeType}
              onChange={(e) => {
                setCodeType(e.target.value as EAccessCodeType)
              }}
              disabled={isUpdateForm}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput label="Reason for Code" name="reason" fullWidth />
          </Grid>

          {codeType === EAccessCodeType.institutionAccessCode && (
            <>
              {/*Organization fields*/}
              {!isUpdateForm && (
                <Grid item xs={12} sm={12}>
                  <FormSelect
                    label="Organization"
                    name="organizationId"
                    fullWidth
                    value={organizationId}
                    options={organizationsOptions}
                    onChange={handleChangeOrganization}
                    disabled={isUpdateForm}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={4}>
                <FormInput
                  label="Organization Name"
                  name="organizationName"
                  required
                  fullWidth
                  disabled={organizationId !== 0 || isUpdateForm}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormInput
                  label="Two-Digit Organization Number"
                  name="twoDigitsOrg"
                  required
                  fullWidth
                  disabled={organizationId !== 0 || isUpdateForm}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormSelect
                  label="Organization Type"
                  name="organizationType"
                  fullWidth
                  required
                  options={organizationTypes}
                  disabled={organizationId !== 0 || isUpdateForm}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormInput label="Specific Unit Name" name="specificUnitName" required fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormInput
                  label="Two-digit Specific Unit Letter/ Number"
                  name="twoDigitsSpecific"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  label="Two-Letter State Location of Trainees"
                  name="state"
                  fullWidth
                  required
                  options={twoLettersStatesUSA}
                />
              </Grid>

              {!isUpdateForm &&
                ManagerTypeOrder.map((managerType) => {
                  return (
                    <Fragment key={`manager-fields-${managerType}`}>
                      <Grid item xs={12} sm={12}>
                        <FormSelect
                          label={ManagerViewDataByType[managerType].label}
                          name={`${managerType}Id`}
                          fullWidth
                          value={managerIds[managerType]}
                          options={managerOptions[managerType]}
                          onChange={handleChangeManagerFromSelect(managerType)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormInput
                          label={`${ManagerViewDataByType[managerType].label} First Name`}
                          name={`${managerType}FirstName`}
                          required
                          fullWidth
                          disabled={isDisabledCreateManager(managerType)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormInput
                          label={`${ManagerViewDataByType[managerType].label} Last Name`}
                          name={`${managerType}LastName`}
                          required
                          fullWidth
                          disabled={isDisabledCreateManager(managerType)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormInput
                          label={`${ManagerViewDataByType[managerType].label} Email`}
                          name={`${managerType}Email`}
                          required
                          fullWidth
                          disabled={isDisabledCreateManager(managerType)}
                        />
                      </Grid>
                    </Fragment>
                  )
                })}
            </>
          )}

          <Grid item xs={6} sm={4}>
            <FormDatePicker label="Effective Date" name="effectiveDate" required fullWidth />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormDatePicker
              label="Expiry Date"
              name="expiryDate"
              required
              fullWidth
              onChange={handleChangeExpiryDate}
            />
          </Grid>
          {codeType === EAccessCodeType.institutionAccessCode && (
            <>
              <Grid item xs={6} sm={4}>
                <FormInput
                  label="Duration (eg. 1y 2md 3d)"
                  required
                  fullWidth
                  {...register('duration', {
                    onChange: handleChangeDuration,
                  })}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6} sm={4}>
            <FormInput label="Number of Users" name="numberOfUsers" required fullWidth />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
            {isUpdateForm ? 'Update' : 'Generate'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export const AccessCodeGenerationModal = (props: Props) => {
  const { isOpen, handleClose, editedItem } = props

  return (
    <UIModal
      isOpen={isOpen}
      onClose={handleClose}
      width={1000}
      title={!!editedItem ? 'Update Access Code' : 'Access Code Generation'}
    >
      <ModalContent {...props} />
    </UIModal>
  )
}
