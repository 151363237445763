import { Stack, Tab, Tabs } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { getTotalEngagementPerUserReport } from 'api/reports'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { CSVField, useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, OrgReportTypes } from 'types'
import { getRoute, useGetDataSource } from 'utils'
import { createEngagementColumn, createEngagementCsvColumn } from '../../helpers/engagementColumn'
import { prepareDateRangeParams } from '../../helpers/prepareDateRangeParams'
import { useDateRange } from '../../hooks/useDateRange'
import { DateRangeToolbar } from '../DateRangeToolbar/DateRangeToolbar'

const activeColumnDefs: ColDef[] = [
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  createEngagementColumn('Total Time Online', 'totalEngagement'),
  { headerName: 'Finished Lessons', field: 'passedLessonsCount', filter: 'agNumberColumnFilter' },
  { headerName: 'Passed Courses', field: 'passedCoursesCount', filter: 'agNumberColumnFilter' },
]

const csvFieldsActive: CSVField[] = [
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  createEngagementCsvColumn('Total Time Online', 'totalEngagement'),
  { label: 'Finished Lessons', value: 'passedLessonsCount' },
  { label: 'Passed Courses', value: 'passedCoursesCount' },
]

type IReportsTabOptions = {
  [key in OrgReportTypes]: {
    columns: ColDef[]
    title: string
    csvName: string
    csvFields: CSVField[]
    hasDateFilter: boolean
  }
}

const ReportsTabOptions: IReportsTabOptions = {
  [OrgReportTypes.Completion]: {
    title: 'Training Completion per User',
    columns: activeColumnDefs,
    csvName: 'Training Completion per User.csv',
    csvFields: csvFieldsActive,
    hasDateFilter: true,
  },
}

const ReportOrder = [OrgReportTypes.Completion]

export const ReportOrganizationCompletion = () => {
  const navigate = useNavigate()
  const { type } = useParams()

  const selectedTab = useMemo(() => {
    if (!type) return OrgReportTypes.Completion

    return type as OrgReportTypes
  }, [type])

  const tabOptions = useMemo(() => ReportsTabOptions[selectedTab], [selectedTab])

  const handleChangeSelectedTab = useCallback(
    (_: React.SyntheticEvent, newValue: OrgReportTypes) => {
      const route = getRoute(routes.reports_engagement_user, { engagementType: newValue })
      navigate(route)
    },
    [navigate],
  )

  const columns = tabOptions.columns

  const {
    rangeType,
    year,
    month,
    selectedDate,
    dateInterval,
    onChangeDateInterval,
    onChangeRange,
    onChangeYear,
    onChangeMonth,
    setSelectedDate,
  } = useDateRange()

  const getDataWrapper = useCallback(
    (req: BaseTableRequest) => {
      const dateParams = prepareDateRangeParams(rangeType, year, month, selectedDate, dateInterval)
      switch (selectedTab) {
        case OrgReportTypes.Completion:
          return getTotalEngagementPerUserReport({ ...req, params: dateParams })
      }
    },
    [dateInterval, month, rangeType, selectedDate, selectedTab, year],
  )

  const getDataSourceActive = useGetDataSource(getDataWrapper)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSourceActive)

  const { csvLoading, onExportCSV } = useExportCSV(
    getDataWrapper,
    gridApi,
    columnApi,
    tabOptions.csvFields,
    tabOptions.csvName,
  )

  useRefreshTable({ params: { rangeType, year, month, selectedDate }, gridApi })

  return (
    <>
      <TableToolbar
        left={
          <Tabs
            value={type}
            onChange={handleChangeSelectedTab}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons={false}
          >
            {ReportOrder.map((type) => (
              <Tab key={type} value={type} label={getTitle(ReportsTabOptions[type].title)} />
            ))}
          </Tabs>
        }
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />

      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        {tabOptions.hasDateFilter && (
          <DateRangeToolbar
            rangeType={rangeType}
            year={year}
            month={month}
            selectedDate={selectedDate}
            dateInterval={dateInterval}
            onChangeDateInterval={onChangeDateInterval}
            onChangeRange={onChangeRange}
            onChangeYear={onChangeYear}
            onChangeMonth={onChangeMonth}
            setSelectedDate={setSelectedDate}
          />
        )}
      </Stack>

      <InfinityAgGrid columnDefs={columns} pagination onGridReady={onGridReady} />
    </>
  )
}
