import React, { useState } from 'react'
import { Checkbox, FormControlLabel, Stack, Typography, CheckboxProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import ConfirmModal from 'components/modals/ConfirmModal'

type Props = {
  name: string
  label: string
  confirmText?: string
} & CheckboxProps

const FormCheckbox = ({ name, label, confirmText, ...rest }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const [confirmState, setConfirmState] = useState({
    open: false,
    confirmFn: () => undefined,
  })

  const handleClose = () => {
    setConfirmState((state) => ({ ...state, open: false }))
  }

  const handleConfirm = () => {
    confirmState.confirmFn()
    setConfirmState((state) => ({ ...state, open: false, confirmFn: () => undefined }))
  }

  const handleChange = (value: boolean, onChange: (v: boolean) => void) => {
    if (!confirmText || !value) {
      onChange(value)
      return
    }
    setConfirmState((state) => ({
      ...state,
      open: true,
      confirmFn: () => {
        onChange(value)
        return undefined
      },
    }))
  }

  return (
    <>
      <Stack>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <Checkbox
                  name={name}
                  checked={field.value}
                  color="primary"
                  onChange={(e) => handleChange(e.target.checked, field.onChange)}
                  {...rest}
                />
              )}
            />
          }
          label={label}
        />
        {errors[name] ? (
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }} color="error">
            {(errors[name] as { message?: string })?.message || ''}
          </Typography>
        ) : (
          ''
        )}
      </Stack>
      {confirmState.open && (
        <ConfirmModal
          title=""
          loading={false}
          body={confirmText}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  )
}

export default FormCheckbox
