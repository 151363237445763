import { ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import dateRenderer from 'components/column-renderers/date'
import { useMemo } from 'react'
import { OrganizationalUser, UserTypeLabels } from 'types'
import { isDefined } from 'utils'
import { UserOrgAccessCodesCell } from '../components/UserOrgAccessCodesCell/UserOrgAccessCodesCell'

export function useUserOrgColumns() {
  return useMemo(
    () => [
      { headerName: 'First Name', field: 'firstname', filter: 'agTextColumnFilter' },
      { headerName: 'Last Name', field: 'lastname', filter: 'agTextColumnFilter' },
      { headerName: 'Email', field: 'email', filter: 'agTextColumnFilter' },
      {
        headerName: 'Organizational Name',
        field: 'organizationName',
        filter: 'agTextColumnFilter',
        minWidth: 420,
      },
      {
        headerName: 'Role',
        colId: 'type',
        cellRenderer: (props: ICellRendererParams<OrganizationalUser>) => {
          const type = props.data?.type
          if (isDefined(type)) {
            const role = type[0]
            return UserTypeLabels[role]
          }
          return PLACEHOLDER
        },
      },
      {
        headerName: 'Institutional Assess Codes',
        field: 'totalOfAccessCodes',
        minWidth: 250,
        cellRenderer: (props: ICellRendererParams<OrganizationalUser>) => {
          const ownedAccessCodes = props.data?.ownedAccessCodes
          if (ownedAccessCodes?.length) {
            return <UserOrgAccessCodesCell accessCodes={ownedAccessCodes} />
          }
          return PLACEHOLDER
        },
      },
      {
        headerName: 'Effective Date',
        cellRenderer: dateRenderer('minEffectiveDateOfAccessCodes'),
        colId: 'minEffectiveDateOfAccessCodes',
      },
      {
        headerName: 'Expiration Date',
        cellRenderer: dateRenderer('maxExpiryDateOfAccessCodes'),
        colId: 'maxExpiryDateOfAccessCodes',
      },
    ],
    [],
  )
}
