import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import {
  defaultModalStyle,
  defaultModalZIndex,
  EMAIL_IS_INVALID,
  FIELD_IS_REQUIRED,
  minButtonWidth,
} from 'const'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { CareTeam, Response } from 'types'
import { isDefined } from 'utils'
import { object, string, TypeOf } from 'zod'
import { createCareTeam, getCareTeamById, updateCareTeam } from '../../api/careTeams'
import { useRefetchCareTeam } from '../../features/Timeline/hooks/useRefetchCareTeam'
import { useAuthContext } from '../context/AuthProvider'
import FormInput from '../form-elements/FormInput'
import NotificationSys from '../NotificationSystem'
import ModalFailPlaceholder from '../placeholders/ModalFailPlaceholder'
import Loader from '../placeholders/ModalLoadingPlaceholder'

interface Props {
  id?: number
  isOpen: boolean
  handleClose: () => void
}

const formSchema = object({
  firstName: string().min(1, FIELD_IS_REQUIRED),
  lastName: string().min(1, FIELD_IS_REQUIRED),
  position: string(),
  email: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
  phone: string().min(1, FIELD_IS_REQUIRED),
  addressLine1: string().min(1, FIELD_IS_REQUIRED),
  comments: string(),
})

type FormType = TypeOf<typeof formSchema>

const defaultValues = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: '',
  addressLine1: '',
  comments: '',
}

const FormContent = (
  props: Pick<Props, 'id' | 'handleClose' | 'isOpen'> & { values: CareTeam | null },
) => {
  const { handleClose, id, values } = props
  const { user } = useAuthContext()

  const refetch = useRefetchCareTeam()

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: values ? values : defaultValues,
  }

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const createMutation = useMutation(createCareTeam, {
    onSuccess: () => {
      handleClose()
      refetch()
      NotificationSys.showSuccess(`Care Team Member successfully created`)
    },
  })

  const updateMutation = useMutation(updateCareTeam, {
    onSuccess: () => {
      handleClose()
      refetch()
      NotificationSys.showSuccess(`Care Team Member successfully updated`)
    },
  })

  const onSubmitHandler: SubmitHandler<FormType> = (values: FormType) => {
    const baseRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      position: values.position,
      phone: values.phone,
      addressLine1: values.addressLine1,
      addressLine2: '',
      comments: values.comments,
    }
    if (isDefined(id)) {
      updateMutation.mutate({
        ...baseRequest,
        id,
      })
    } else {
      createMutation.mutate({
        ...baseRequest,
        userId: user?.id ?? -1,
      })
    }
  }

  const isLoading = createMutation.isLoading || updateMutation.isLoading

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Stack spacing={1.5}>
          <FormInput label="First Name" name="firstName" fullWidth />
          <FormInput label="Last Name" name="lastName" fullWidth />
          <FormInput label="Position" name="position" fullWidth />
          <FormInput label="Email" name="email" fullWidth />
          <FormInput label="Phone Number" name="phone" fullWidth />
          <FormInput label="Address" name="addressLine1" fullWidth />
          <FormInput label="Comments" name="comments" fullWidth multiline />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button disabled={isLoading} variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton loading={isLoading} variant="contained" type="submit" sx={minButtonWidth}>
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}

const ModalContent = (props: Pick<Props, 'id' | 'handleClose' | 'isOpen'>) => {
  const { id, handleClose, isOpen } = props

  const careQuery = useQuery(['getCareTeams', { id }], () => getCareTeamById(id ?? -1), {
    enabled: !!id,
    select: (response: Response<CareTeam>) => response.data,
    cacheTime: 0,
    staleTime: 0,
  })

  const data: CareTeam | undefined = careQuery.data

  return (
    <Box sx={defaultModalStyle}>
      <Typography variant="h5" mb={2}>
        {isDefined(id) ? 'Update Member' : 'Add Member'}
      </Typography>

      <ModalFailPlaceholder
        show={!!id && !data && !careQuery.isLoading}
        py={10}
        error="Can't load data of the care member"
        handleClose={handleClose}
      />

      <Loader show={careQuery.isLoading} py={12} />

      {!careQuery.isLoading && !!id && data && (
        <FormContent isOpen={isOpen} id={id} values={data || null} handleClose={handleClose} />
      )}
      {!id && <FormContent isOpen={isOpen} id={id} values={null} handleClose={handleClose} />}
    </Box>
  )
}

const AddMemberModal = (props: Props) => {
  const { id, isOpen, handleClose } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      {isOpen ? <ModalContent id={id} handleClose={handleClose} isOpen={isOpen} /> : <Box />}
    </Modal>
  )
}

export default AddMemberModal
