export default function formHelperTextOverride(): object {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontWeight: 400,
          textTransform: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#d32f2f',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.6,
          },
        },
      },
    },
  }
}
