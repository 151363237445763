import { ColDef, GridReadyEvent } from 'ag-grid-community'
import dateRenderer from 'components/column-renderers/date'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import { UIModal } from 'features/UI'
import { useShowControl } from 'hooks/useShowControl'
import { SyntheticEvent, useMemo } from 'react'
import { OwnedAccessCode } from 'types'

export function UserOrgAccessCodesCell({ accessCodes }: { accessCodes: OwnedAccessCode[] }) {
  const [isOpen, onOpen, onClose] = useShowControl()

  const handleOpen = (event: SyntheticEvent) => {
    event.preventDefault()
    onOpen()
  }

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit()
  }

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Access Code',
        field: 'code',
      },
      {
        headerName: 'State',
        field: 'specificState',
      },
      {
        headerName: 'Unit Name',
        field: 'specificUnitName',
      },
      {
        headerName: 'Effective Date',
        cellRenderer: dateRenderer('effectiveDate'),
        colId: 'effectiveDate',
      },
      {
        headerName: 'Expire Date',
        cellRenderer: dateRenderer('expiryDate'),
        colId: 'expiryDate',
      },
      {
        headerName: 'Number of Users',
        field: 'numberOfUsers',
      },
    ]
  }, [])

  return (
    <div>
      <a href="" onClick={handleOpen}>
        {accessCodes.length}
      </a>

      <UIModal isOpen={isOpen} onClose={onClose} width={788} title="Access Codes">
        <div
          className="ag-theme-alpine"
          style={{ height: '400px', width: '740px', fontSize: '1rem' }}
        >
          <InfinityAgGrid
            rowData={accessCodes}
            columnDefs={columnDefs}
            rowModelType="clientSide"
            onGridReady={handleGridReady}
            floatingFiltersHeight={0}
          />
        </div>
      </UIModal>
    </div>
  )
}
