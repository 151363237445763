import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import {
  checkPathwayNameUniqueness,
  checkPathwayTypeNameUniqueness,
  createPathway,
  updatePathway,
  updatePathwayType,
} from 'api/pathways'
import FormInput from 'components/form-elements/FormInput'
import NotificationSys from 'components/NotificationSystem'
import { FIELD_IS_REQUIRED } from 'const'
import { UIModal } from 'features/UI'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Nullish, Pathway } from 'types'
import { withIdParam } from 'utils'
import zod, { TypeOf } from 'zod'

interface Props {
  isOpen: boolean
  onClose: () => void
  onRefresh: () => void
  editedItem: Nullish<Pathway>
}

const formSchema = zod.object({
  name: zod.string().min(1, FIELD_IS_REQUIRED).max(50, 'Please enter a maximum of 50 characters'),
  type: zod.string().min(1, FIELD_IS_REQUIRED).max(50, 'Please enter a maximum of 50 characters'),
})

type FormType = TypeOf<typeof formSchema>

function PathwayCreateModalContent({ editedItem, onClose, onRefresh }: Props) {
  const navigate = useNavigate()

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: editedItem ? editedItem.name : '',
      type: editedItem ? editedItem.trainingPathwayTypeName : '',
    },
  }

  const methods = useForm<FormType>(formSettings)

  const { handleSubmit } = methods

  const createMutation = useMutation(createPathway, {
    onSuccess: (response) => {
      navigate(withIdParam(routes.pathwayEdit, response.data.id))
      onClose()
    },
  })

  const updatePathwayMutation = useMutation(updatePathway)
  const updatePathwayTypeMutation = useMutation(updatePathwayType)

  const onUpdate = async (data: FormType) => {
    if (!editedItem) return

    try {
      if (editedItem.name !== data.name) {
        const uniqNameResponse = await checkPathwayNameUniqueness({ name: data.name })

        if (!uniqNameResponse.data.success) {
          NotificationSys.showWarning('Pathway with such name already exists')
          return
        }
      }

      if (editedItem.trainingPathwayTypeName !== data.type) {
        const uniqTypeResponse = await checkPathwayTypeNameUniqueness({ name: data.type })

        if (!uniqTypeResponse.data.success) {
          NotificationSys.showWarning('Pathway Type with such name already exists')
          return
        }
      }

      await updatePathwayMutation.mutateAsync({
        id: editedItem.id,
        name: data.name,
      })

      await updatePathwayTypeMutation.mutateAsync({
        id: editedItem.trainingPathwayTypeId,
        name: data.name,
        title: data.type,
      })

      onRefresh()
      onClose()
    } catch (error) {}
  }

  const onSubmitHandler = async (data: FormType) => {
    try {
      if (!!editedItem) {
        onUpdate(data)
        return
      }

      const [uniqNameResponse, uniqTypeResponse] = await Promise.all([
        checkPathwayNameUniqueness({ name: data.name }),
        checkPathwayTypeNameUniqueness({ name: data.type }),
      ])

      if (!uniqNameResponse.data.success) {
        NotificationSys.showWarning('Pathway with such name already exists')
        return
      }

      if (!uniqTypeResponse.data.success) {
        NotificationSys.showWarning('Pathway Type with such name already exists')
        return
      }

      createMutation.mutate({
        name: data.name,
        trainingPathwayTypeName: data.type,
        trainingPathwayTypeTitle: data.type,
      })
      return
    } catch (error) {}
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
        <Stack spacing={2}>
          <FormInput label="Pathway Name" name="name" fullWidth={true} />

          <FormInput label="Pathway Type" name="type" fullWidth={true} />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>

            <LoadingButton
              loading={
                createMutation.isLoading ||
                updatePathwayMutation.isLoading ||
                updatePathwayTypeMutation.isLoading
              }
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export function PathwayCreateModal(props: Props) {
  return (
    <UIModal
      title={props.editedItem ? 'Rename Pathway' : 'Add Pathway'}
      width={500}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <PathwayCreateModalContent {...props} />
    </UIModal>
  )
}
