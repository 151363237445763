import { getCodes } from 'api/accessCode'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { useAccessCodeRowStyle } from 'features/AccessManagement/hooks/getAccessCodeRowStyle'
import { useOrganizationAccessCodesColumns } from 'features/Organization/hooks/useOrganizationAccessCodesColumns'
import { useGridControl } from 'hooks/useGridControl'
import { useGetDataSource } from 'utils'

export function OrganizationAccessManagement() {
  const getDataSource = useGetDataSource(getCodes)
  const { onGridReady } = useGridControl(getDataSource)
  const getRowStyle = useAccessCodeRowStyle()
  const columns = useOrganizationAccessCodesColumns()

  return (
    <>
      <TableToolbar title="Institutional Access Codes" />
      <InfinityAgGrid
        pagination
        columnDefs={columns}
        getRowStyle={getRowStyle}
        onGridReady={onGridReady}
      />
    </>
  )
}
