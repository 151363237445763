import styled from '@emotion/styled'
import { Colors } from 'styles/colors'
import { Stack } from '@mui/material'

export const IconContainer = styled('div')`
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
`

export const StackContainer = styled(Stack)`
  display: flex;
  background: #fff;
  padding: 16px;
  margin-top: 8px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${Colors.PINK};
  gap: 16px;
`
