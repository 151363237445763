import { RowClassParams } from 'ag-grid-community'
import { useCallback } from 'react'
import { isDateSameOrAfter } from 'utils'

export function useAccessCodeRowStyle() {
  return useCallback((params: RowClassParams) => {
    if (!params.data) {
      return
    }

    const { expiryDate, numberOfUsers, timesUsed } = params.data
    const canBeUsed = numberOfUsers - timesUsed > 0
    const isExpired = !isDateSameOrAfter(expiryDate)
    if (!canBeUsed || isExpired) {
      return { background: 'rgba(0, 0, 0, 0.04)' }
    }

    return
  }, [])
}
