import { addLearnArticleEngagementMetric, addQueryEngagementMetric } from 'api/metrics'
import { useAuthContext } from 'components/context/AuthProvider'
import { useCallback } from 'react'
import { useMetric } from 'services/Metric'
import { IMetricScope, IMetricType, Query } from 'types'
import { formatMetricDate } from 'utils'

export function QueryMetric({ query }: { query: Query }) {
  const { user } = useAuthContext()

  const handleSaveLearnLibraryMetric = useCallback(
    async (value: number, uuid: string, scope: IMetricScope) => {
      if (!user || !query.learnArticleId) {
        return
      }

      await addLearnArticleEngagementMetric({
        userId: user.id,
        learnArticleId: query.learnArticleId,
        learnTopicId: query.learnArticle.topicId,
        scope,
        engagedFrom: formatMetricDate(value),
        engagedTo: formatMetricDate(),
      })
    },
    [query, user],
  )

  useMetric({
    type: IMetricType.learnArticle,
    entityId: query.id,
    isImmediateStart: true,
    hasIdleTimer: true,
    idleLimitMin: 5,
    scope: IMetricScope.query,
    onSave: handleSaveLearnLibraryMetric,
  })

  const handleSaveQueryMetric = useCallback(
    async (value: number, uuid: string, scope: IMetricScope) => {
      if (!user) {
        return
      }

      await addQueryEngagementMetric({
        userId: user.id,
        queryId: query.id,
        learnArticleId: query.learnArticleId,
        scope,
        engagedFrom: formatMetricDate(value),
        engagedTo: formatMetricDate(),
      })
    },
    [query, user],
  )

  useMetric({
    type: IMetricType.query,
    entityId: query.id,
    isImmediateStart: true,
    hasIdleTimer: true,
    idleLimitMin: 5,
    scope: IMetricScope.query,
    onSave: handleSaveQueryMetric,
  })

  return null
}
