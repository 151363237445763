import { SummarizeOutlined } from '@mui/icons-material'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import GroupIcon from '@mui/icons-material/Group'
import KeyIcon from '@mui/icons-material/Key'
import List from '@mui/material/List'
import DrawerHeaderFiller from 'components/drawer/common/DrawerHeaderFiller'
import NavItem from 'components/drawer/common/NavItem'
import { routes } from 'routes/routes'
import { OrgReportTypes } from 'types'
import { getRoute } from 'utils/url'

const navItems = [
  {
    icon: <GroupIcon />,
    path: routes.orgUsers,
    label: 'Users',
  },
  {
    icon: <KeyIcon />,
    path: routes.orgAccessManagement,
    label: 'Access Management',
  },
  {
    icon: <SummarizeOutlined />,
    path: getRoute(routes.orgReports, { type: OrgReportTypes.Completion }),
    label: 'Reports',
  },
  {
    icon: <TipsAndUpdatesIcon />,
    path: routes.orgTips,
    label: 'Tips Panel',
  },
]

export function OrganizationDrawerContent() {
  return (
    <>
      <DrawerHeaderFiller />

      <List disablePadding>
        {navItems.map((item) => (
          <NavItem key={item.path} icon={item.icon} path={item.path} isSmall>
            {item.label}
          </NavItem>
        ))}
      </List>
    </>
  )
}
