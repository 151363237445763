import { Stack, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { FilterModelBoolean, FilterModelNullishText, Nullish } from 'types'

enum OrgStatus {
  ACTIVE = 'active',
  LEFT = 'left',
}

interface StatusOption {
  value: Nullish<OrgStatus>
  label: string
}

export function useOrgStatusFilter() {
  const [value, setValue] = useState<Nullish<StatusOption>>(null)

  const filterValue: Record<string, FilterModelBoolean | FilterModelNullishText> | {} =
    useMemo(() => {
      if (value === null) return {}

      if (value.value === OrgStatus.ACTIVE) {
        return {
          isActive: {
            type: 'boolean',
            op: 'equals',
            value: true,
          },
        }
      }

      if (value.value === OrgStatus.LEFT) {
        return {
          leftOrganizationAt: {
            type: 'text',
            op: 'notBlank',
            value: null,
          },
        }
      }

      return {}
    }, [value])

  const options = useMemo(() => {
    return [
      {
        value: null,
        label: 'All',
      },
      {
        value: OrgStatus.ACTIVE,
        label: 'Active',
      },
      {
        value: OrgStatus.LEFT,
        label: 'Left Organization',
      },
    ]
  }, [])

  const handleChange = useCallback((_: SyntheticEvent, newValue: Nullish<StatusOption>) => {
    setValue(newValue as StatusOption)
  }, [])

  const StatusFilter = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" className="gap-8">
        <Typography>Status</Typography>

        <Autocomplete
          multiple={false}
          options={options}
          value={value}
          onChange={handleChange}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              name="role"
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              }}
            />
          )}
        />
      </Stack>
    )
  }, [handleChange, options, value])

  return { StatusFilter, statusFilterValue: filterValue }
}
