import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { UserType } from 'types'
import { useAuthContext } from '../components/context/AuthProvider'
import { routes } from '../routes/routes'

export const useCheckAuthorizationAndRedirect = (types: UserType[]): React.ReactNode => {
  const { user, checkIsLoggedIn } = useAuthContext()

  return useMemo(() => {
    if (!user || !checkIsLoggedIn()) {
      return <Navigate to={routes.signIn} replace />
    }
    if (!types.some((it) => user.type.some((t) => t === it))) {
      switch (user.type[0]) {
        case UserType.ADMIN: {
          return <Navigate to={routes.users} replace />
        }
        case UserType.CARETEAM:
        case UserType.CAREGIVER:
        case UserType.TRAINEE:
          return <Navigate to={routes.journey} replace />
        case UserType.ORG_SUPERVISOR:
        case UserType.ORG_OWNER:
        case UserType.ORG_FACILITY_MANAGER:
        case UserType.ORG_REGIONAL_MANAGER:
          return <Navigate to={routes.orgUsers} replace />
      }
    }
    return null
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps
}
