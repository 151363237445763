import { getCodesDropdown } from 'api/accessCode'
import { getPathways } from 'api/pathways'
import { getUser } from 'api/users'
import PageTitle from 'components/page/PageTitle'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { UserHistory } from '../UserHistory/UserHistory'
import { UserOrganizationDetailsForm } from './UserOrganizationDetailsForm'

export function UserOrganizationDetails({ userId }: { userId: number }) {
  const [needUpdateHistory, setNeedUpdateHistory] = useState(false)
  const userQuery = useQuery(['user', userId], () => getUser({ id: userId }))
  const pathways = useQuery('pathways', () => getPathways())
  const accessCodes = useQuery('access-codes', () => getCodesDropdown())

  const handleAfterUpdate = useCallback(() => {
    userQuery.refetch()
    setNeedUpdateHistory(true)
  }, [userQuery])

  const handleResetNeedUpdateHistory = useCallback(() => {
    setNeedUpdateHistory(false)
  }, [])

  if (userQuery.isLoading || pathways.isLoading || accessCodes.isLoading) {
    return <LoadingPlaceholder />
  }

  if (!userQuery.data || !pathways.data || !accessCodes.data) {
    return <div>Something went wrong</div>
  }

  return (
    <>
      <PageTitle>Edit User View</PageTitle>

      <UserOrganizationDetailsForm
        user={userQuery.data.data}
        pathways={pathways.data}
        accessCodes={accessCodes.data?.data || []}
        onAfterUpdate={handleAfterUpdate}
      />

      <UserHistory
        userId={userId}
        pathways={pathways.data}
        needUpdate={needUpdateHistory}
        resetNeedUpdate={handleResetNeedUpdateHistory}
      />
    </>
  )
}
