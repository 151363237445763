import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { createMediaFile } from 'api/mediaFiles'
import { defaultModalStyle, defaultModalZIndex, FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ImageType } from 'react-images-uploading/dist/typings'
import { isAspectRatio16by9 } from 'utils'
import { any, object, string, TypeOf } from 'zod'
import FormInput from '../../form-elements/FormInput'
import FormImageUpload from '../../image-upload/FormImageUpload'

export interface NameAndIconDTO {
  name: string
  icon: string | ImageType | null
}

export interface NameAndIconRequest {
  name: string
  icon: number | null
}

interface InnerProps {
  title: string
  label: string
  errorText?: string
  buttonText: string
  value?: NameAndIconDTO
  isOpen: boolean
  handleClose: () => void
  onSubmit: (request: NameAndIconRequest) => void
  loading: boolean
  setLoading: (value: boolean) => void
  hasRatioRestrictions?: boolean
}

const modalStyle = {
  ...defaultModalStyle,
  width: 420,
}

const ModalContent = (props: InnerProps) => {
  const {
    value,
    handleClose,
    title,
    label,
    errorText = FIELD_IS_REQUIRED,
    buttonText,
    onSubmit,
    loading,
    setLoading,
    hasRatioRestrictions,
  } = props

  const formSchema = useMemo(() => {
    return object({
      name: string().min(1, errorText),
      icon: string()
        .nullable()
        .refine((val) => val ?? false, FIELD_IS_REQUIRED)
        .or(
          object({
            dataURL: string(),
            file: any(),
          }),
        ),
    }).refine(
      async (data) => {
        if (data.icon && typeof data.icon !== 'string' && hasRatioRestrictions) {
          const isRatioOk = await isAspectRatio16by9(data.icon.dataURL)
          return isRatioOk
        }

        return true
      },
      {
        message: 'The image resolution is incorrect. It should be 16:9.',
        path: ['icon'],
      },
    )
  }, [errorText, hasRatioRestrictions])

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: value?.name || '',
        icon: value?.icon || null,
      },
    }
  }, [formSchema, value?.icon, value?.name])

  type FormType = TypeOf<typeof formSchema>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit } = methods

  const handleUploadIcon = async (values: NameAndIconDTO) => {
    if (!values.icon) return
    if (typeof values.icon === 'string') return
    if (!values.icon.file) return

    try {
      setLoading(true)
      const mediaResponse = await createMediaFile(values.icon.file, () => {})
      return mediaResponse.data.id
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmitHandler: SubmitHandler<FormType> = async (values: FormType) => {
    const bannerMediaFileId = await handleUploadIcon(values)
    onSubmit({
      name: values.name,
      icon: bannerMediaFileId || null,
    })
  }

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" mb={3}>
        {title}
      </Typography>

      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} width="100%" noValidate>
          <Stack spacing={2}>
            <FormInput label={label} name="name" fullWidth={true} checkTouchField={false} />
            <FormImageUpload name="icon" hasRatioRestrictions={hasRatioRestrictions} />
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
            <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" sx={minButtonWidth}>
              {buttonText}
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  )
}

const ModalWithTextAndIcon = (props: InnerProps) => {
  const { isOpen } = props

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      <div>{isOpen ? <ModalContent {...props} /> : <Box />}</div>
    </Modal>
  )
}

export default React.memo(ModalWithTextAndIcon)
