import { AccessCodeDropdownItem } from './accessCode.types'
import { CohortShort } from './cohort.types'
import { Nullish } from './common.types'

export enum UserType {
  ADMIN = 'ADMIN',
  CAREGIVER = 'CAREGIVER',
  CARETEAM = 'CARETEAM',
  TRAINEE = 'TRAINEE',
  ORG_OWNER = 'ORG_OWNER',
  ORG_REGIONAL_MANAGER = 'ORG_REGIONAL_MANAGER',
  ORG_FACILITY_MANAGER = 'ORG_FACILITY_MANAGER',
  ORG_SUPERVISOR = 'ORG_SUPERVISOR',
}

export const UserTypeLabels = {
  [UserType.ADMIN]: 'Admin',
  [UserType.CAREGIVER]: 'Caregiver',
  [UserType.CARETEAM]: 'Care Team',
  [UserType.TRAINEE]: 'Trainee',
  [UserType.ORG_OWNER]: 'Owner',
  [UserType.ORG_REGIONAL_MANAGER]: 'Regional Manager',
  [UserType.ORG_FACILITY_MANAGER]: 'Facility Manager',
  [UserType.ORG_SUPERVISOR]: 'Supervisor',
}

export const UserOrganizationalRoles = [
  UserType.ORG_OWNER,
  UserType.ORG_REGIONAL_MANAGER,
  UserType.ORG_FACILITY_MANAGER,
  UserType.ORG_SUPERVISOR,
]

export enum UserTypeTab {
  ADMIN = 'ADMIN',
  CAREGIVER = 'CAREGIVER',
  CARETEAM = 'CARETEAM',
  TRAINEE = 'TRAINEE',
  ORG = 'ORG',
}

export type User = {
  id: number
  firstname: string
  lastname: string
  email: string
  password: string
  trainingPathwayTypeName?: string
  createdAt: string
  type: UserType[]
  accessCodeExpiryDate?: string | null
  totalEngagement: string // '04:05:00'
  personOfConcernFirstName: string
  personOfConcernLastName: string
  currentType: UserType
  carePersonId?: number
  isTrainingPathwayTypeChanged: Nullish<boolean>
  role?: string
  licenseExpiredAt?: string | null
  leftOrganizationAt?: string | null
  accessCode?: {
    id: number
    code: string
    specificUnitName: string
    specificState: string
  }
}

export enum EUserEducation {
  highSchoolOrLess = 'highSchoolOrLess',
  beyondHighSchool = 'beyondHighSchool',
}

export enum EUserExperience {
  lessThan5Years = 'lessThan5Years',
  equalOrGreaterThan5Years = 'equalOrGreaterThan5Years',
}

export enum EUserLanguage {
  english = 'english',
  spanish = 'spanish',
  chinese = 'chinese',
  filipino = 'filipino',
  other = 'other',
}

export enum ECareLevel {
  veryLittle = 'veryLittle',
  fewThings = 'fewThings',
  mostThings = 'mostThings',
  withAll = 'withAll',
}

export enum ETimelineStatus {
  none = 'none',
  inProgress = 'inProgress',
}

export enum ELearnLibraryStatus {
  none = 'none',
  inProgress = 'inProgress',
}

export enum EUserHighestDegree {
  preferNotToSay = 'preferNotToSay',
  someHighSchool = 'someHighSchool',
  highSchoolDiploma = 'highSchoolDiploma',
  associateDegree = 'associateDegree',
  bachelorDegree = 'bachelorDegree',
  masterDegree = 'masterDegree',
  phDOrMD = 'phDOrMD',
  other = 'other',
}

export enum EUserRacialDesignation {
  preferNotToSay = 'preferNotToSay',
  africanAmericanOrBlack = 'africanAmericanOrBlack',
  arabAmericanOrMiddleEastern = 'arabAmericanOrMiddleEastern',
  asianAmericanOrSouthAsian = 'asianAmericanOrSouthAsian',
  hispanicOrLatino = 'hispanicOrLatino',
  nativeAmericanOrAlaskaNative = 'nativeAmericanOrAlaskaNative',
  nativeHawaiianOrPacificIslander = 'nativeHawaiianOrPacificIslander',
  white = 'white',
  other = 'other',
}

export type UserModel = {
  id: number
  firstname: string
  lastname: string
  email: string
  cohorts: CohortShort[]
  anonymousId: number
  education: EUserEducation
  experience: EUserExperience
  language: EUserLanguage
  personOfConcernFirstName: string
  personOfConcernLastName: string
  careLevel: Nullish<ECareLevel>
  forgotPasswordSentAt: string
  forgotPasswordToken: string
  trainingPathwayTypeId: number
  trainingPathwayTypeChangedAt: Nullish<string>
  isTrainingPathwayTypeChanged: Nullish<boolean>
  userBasicInfoId: Nullish<number>
  stripeCustomerId: Nullish<number>
  type: UserType[]
  isActive: boolean
  timelineStatus: ETimelineStatus
  learnLibraryStatus: ELearnLibraryStatus
  lastLoggedInAt: string
  totalNumbersOfLogin: number
  carePersonId: Nullish<number>
  createdAt: string
  updatedAt: string
  deletedAt: Nullish<string>
  // survey monkey
  highestDegree: EUserHighestDegree
  otherHighestDegree: string
  racialDesignation: EUserRacialDesignation
  otherRacialDesignation: string
  jobTitle: string
  accessCodeId?: number
  accessCode?: AccessCodeDropdownItem
  leftOrganizationAt?: string
  isUnlicensed?: boolean
}

export interface UserUpdateRequest {
  id: number
  firstname: string
  lastname: string
  email: string
  cohortIds?: number[]
  education?: EUserEducation
  experience?: EUserExperience
  language?: EUserLanguage
  highestDegree?: EUserHighestDegree
  otherHighestDegree?: string
  racialDesignation?: EUserRacialDesignation
  otherRacialDesignation?: string
  jobTitle?: string
  accessCodeId?: number | null
  shouldLeaveOrganization?: boolean
  isUnlicensed?: boolean
  customTrainingPathwayTypeId?: number
}

export interface UserCaregiverUpdateRequest {
  id: number
  firstname: string
  lastname: string
  email: string
  careLevel: ECareLevel
}

export interface UserConfirmPathwayChangesRequest {
  id: number
  isTrainingPathwayTypeChanged: boolean
}

export interface UserPathwayType {
  id: number
  name: string
  title: string
}

export enum EUserUpdateNotice {
  trainingPathwayTypeWillBeChanged = 'trainingPathwayTypeWillBeChanged',
}

export interface UserUpdateNoticeData {
  reason: EUserUpdateNotice
  data: {
    oldTrainingPathwayType: UserPathwayType
    newTrainingPathwayType: UserPathwayType
  }
}

export interface UserHistoryRow {
  createdAt: string
  updatedAt: string
  fieldName: string
  id: number
  newValue: string | number
  oldValue: string | number
  updateAction: string
  userId: number
}

export interface UserUpdater {
  id: number
  email: string
  firstname: string
  lastname: string
}

export interface ContactUsRequest {
  email: string
  subject: string
  text?: string
}
