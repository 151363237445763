import Drawer from '@mui/material/Drawer'
import { Box, Stack, Typography } from '@mui/material'
import { ArticleAudioUserView } from 'features/Article/components/ArticleAudioUserView/ArticleAudioUserView'
import { ArticlePreview } from 'features/Article/components/ArticlePreview/ArticlePreview'
import { UILearnArticleImg } from 'features/UI'
import DrawerContainer from 'components/drawer/common/DrawerContainer'
import DrawerHeader from 'components/drawer/common/DrawerHeader'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { defaultDrawerSX } from 'const'
import { getTipById } from 'api/tips'
import { formatDate } from 'utils'

export const TipDrawerView = ({
  id,
  isOpen,
  handleClose,
}: {
  id: number
  isOpen: boolean
  handleClose: () => void
}) => {
  const { data, loading } = useEntityDataControl({
    id,
    loadFunc: getTipById,
    warning: "Can't load the tip",
  })

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      <DrawerContainer isOpen={isOpen} hasData={!!data} loading={loading} handleClose={handleClose}>
        {!loading && data && (
          <>
            <DrawerHeader handleClose={handleClose}>
              <UILearnArticleImg />
              <span>Tip</span>
            </DrawerHeader>

            <Typography variant="h4">{data.name}</Typography>
            <Stack direction="row" gap={1}>
              <Typography color="textSecondary" variant="body1">
                {`by ${data.updater?.firstname} ${data.updater?.lastname} · 
                ${formatDate(data.createdAt, 'h:mm A dddd Do of MMMM, YYYY')}`}
              </Typography>
            </Stack>

            <ArticleAudioUserView
              enMediaUrl={data.enMediaUrl}
              enMediaFileMimeType={data.enMediaFile?.mimeType}
              esMediaUrl={data.esMediaUrl}
              esMediaFileMimeType={data.esMediaFile?.mimeType}
            />

            {data.note && (
              <Box my={2} fontSize={20}>
                <Typography variant="body1">Note</Typography>
                <Stack direction="row" gap={1}>
                  <Typography color="textSecondary" variant="body1">
                    {data.note}
                  </Typography>
                </Stack>
              </Box>
            )}

            <Box my={2} fontSize={20} minHeight="128px">
              <ArticlePreview data={data.article} />
            </Box>
          </>
        )}
      </DrawerContainer>
    </Drawer>
  )
}
