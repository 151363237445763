import { accessManagementColumns } from 'features/AccessManagement/components/AccessManagement/AccessManagement'
import { useMemo } from 'react'

export function useOrganizationAccessCodesColumns() {
  return useMemo(
    () => [
      accessManagementColumns.codeValue,
      accessManagementColumns.effectiveDate,
      accessManagementColumns.expiryDate,
      accessManagementColumns.numberOfUsers,
      accessManagementColumns.codeCanBeUsed,
      accessManagementColumns.timesUsed,
      {
        ...accessManagementColumns.organizationName,
        filter: null,
        sortable: false,
      },
      accessManagementColumns.specificUnitName,
      accessManagementColumns.state,
      accessManagementColumns.duration,
    ],
    [],
  )
}
