import { Button } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { getPathways } from 'api/pathways'
import columnRenderers from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import {
  columnCreatedBy,
  columnUpdatedBy,
  csvColumnCreatedBy,
  csvColumnUpdatedBy,
  getActionColumn,
} from 'features/UI'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useShowControl } from 'hooks/useShowControl'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Nullish, Pathway } from 'types'
import { formatDate, useGetDataSource, withIdParam } from 'utils'
import { OLD_PATHWAYS_COUNT } from '../../models/Pathway.model'
import { PathwayCreateModal } from '../PathwayCreateModal/PathwayCreateModal'

export function PathwayPageList() {
  const navigate = useNavigate()

  const getDataSource = useGetDataSource(getPathways)
  const { gridApi, columnApi, onGridReady } = useGridControl(getDataSource)

  const [isOpenAddPathwayModal, onOpenAddPathwayModal, onCloseAddPathwayModal] = useShowControl()
  const [pathwayToRename, setPathwayToRename] = useState<Nullish<Pathway>>(null)

  const handleEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.pathwayEdit, id))
    },
    [navigate],
  )

  const handleRename = useCallback(
    (id: number, cellProps: ICellRendererParams<Pathway>) => {
      if (id && cellProps.data) {
        setPathwayToRename(cellProps.data)
        onOpenAddPathwayModal()
      }
    },
    [onOpenAddPathwayModal],
  )

  const handleRefreshTable = useCallback(() => {
    gridApi?.purgeInfiniteCache()
  }, [gridApi])

  const handleClosePathwayModal = useCallback(() => {
    onCloseAddPathwayModal()
    setPathwayToRename(null)
  }, [onCloseAddPathwayModal])

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: handleEdit,
      },
      {
        name: 'Rename',
        hideCondition: (cellProps: ICellRendererParams<Pathway>) => {
          if (cellProps.data) {
            // old pathways can't be renamed, and we have only 5 of them
            return cellProps.data.trainingPathwayTypeId <= OLD_PATHWAYS_COUNT
          }
          return false
        },
        onClick: handleRename,
      },
    ]
  }, [handleEdit, handleRename])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Pathway Name',
        field: 'name',
      },
      {
        headerName: 'Pathway Type',
        field: 'trainingPathwayTypeName',
        minWidth: 270,
      },
      {
        headerName: 'New Records',
        colId: 'newTimelinePostCount',
        cellRenderer: (params: ICellRendererParams<Pathway>) => {
          const count = params.data?.newTimelinePostCount
          return count === -1 ? 'All' : count
        },
      },
      {
        headerName: 'Overall Records',
        field: 'overallRecords',
      },
      {
        headerName: 'Number of Users Assigned',
        field: 'numberOfUsers',
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.date('createdAt'),
        colId: 'createdAt',
      },
      columnCreatedBy(true),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.date('updatedAt'),
        minWidth: 220,
        colId: 'updatedAt',
        sort: 'desc',
      },
      columnUpdatedBy(true),
      getActionColumn(actions),
    ]
  }, [actions])

  const csvFields = [
    {
      label: 'Pathway Name',
      value: 'name',
    },
    {
      label: 'Pathway Type',
      value: 'trainingPathwayTypeName',
    },
    {
      label: 'New Records',
      value: (data: Pathway) => {
        const count = data.newTimelinePostCount
        return count === -1 ? 'All' : count.toString()
      },
    },
    {
      label: 'Overall Records',
      value: 'overallRecords',
    },
    {
      label: 'Number of Users Assigned',
      value: 'numberOfUsers',
    },
    {
      label: 'Creation Date',
      value: (data: Pathway) => formatDate(data.createdAt, 'YYYY-MM-DD HH:mm:ss'),
    },
    csvColumnCreatedBy(),
    {
      label: 'Last Edited',
      value: (data: Pathway) => formatDate(data.updatedAt, 'YYYY-MM-DD HH:mm:ss'),
    },
    csvColumnUpdatedBy(),
  ]

  const { csvLoading, onExportCSV } = useExportCSV(
    getPathways,
    gridApi,
    columnApi,
    csvFields,
    'Pathways.csv',
  )

  return (
    <>
      <TableToolbar
        title="Pathways Overview"
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      >
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={onOpenAddPathwayModal}>
          Add Pathway
        </Button>
      </TableToolbar>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <PathwayCreateModal
        isOpen={isOpenAddPathwayModal}
        editedItem={pathwayToRename}
        onClose={handleClosePathwayModal}
        onRefresh={handleRefreshTable}
      />
    </>
  )
}
