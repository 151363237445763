import { Box, Stack, Tab, Tabs } from '@mui/material'
import { getOrganizationalUsers } from 'api/organization'
import { deleteUser, getUsers } from 'api/users'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { useCohortsFilter } from 'features/Cohorts'
import { getActionColumn } from 'features/UI'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { useTabsControl } from 'hooks/useTabsControl'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, FilterModel, UserTypeTab } from 'types'
import { getRoute, useGetDataSource } from 'utils'
import { useOrgRoleFilter } from '../../hooks/useOrgRoleFilter'
import { csvOrgUserFields, csvTraineeFields, getUserColumnDefs } from './columnDefs'

export const UsersPage = () => {
  const navigate = useNavigate()
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<UserTypeTab>(UserTypeTab.TRAINEE)

  const { cohortsFilterValue, CohortsFilter } = useCohortsFilter()
  const { RoleFilter, rolesFilterValue } = useOrgRoleFilter()

  const getUsersWrapper = useCallback(
    (params: BaseTableRequest) => {
      if (selectedTab === UserTypeTab.ORG) {
        const filter: FilterModel = params.filter || {}

        if (rolesFilterValue) {
          filter.type = rolesFilterValue
        }

        return getOrganizationalUsers({
          ...params,
          filter,
        })
      }

      const baseFilter: FilterModel = params.filter || {}
      const userFilter: FilterModel = {
        ...baseFilter,
        type: {
          type: 'text',
          op: 'equals',
          value: [selectedTab],
        },
      }

      if (cohortsFilterValue) {
        userFilter.cohortId = cohortsFilterValue
      }

      return getUsers({
        ...params,
        filter: userFilter,
      })
    },
    [cohortsFilterValue, rolesFilterValue, selectedTab],
  )

  const getDataSource = useGetDataSource(getUsersWrapper)

  const { gridApi, columnApi, onGridReady } = useGridControl(getDataSource)

  const left = (
    <>
      <Tabs value={selectedTab} onChange={handleChangeSelectedTab} indicatorColor="primary">
        <Tab value={UserTypeTab.TRAINEE} label={getTitle('Trainees')} />
        <Tab value={UserTypeTab.CAREGIVER} label={getTitle('Family Caregivers')} />
        <Tab value={UserTypeTab.CARETEAM} label={getTitle('Care Team')} />
        <Tab value={UserTypeTab.ADMIN} label={getTitle('Admins')} />
        <Tab value={UserTypeTab.ORG} label={getTitle('Organizational Users')} />
      </Tabs>
    </>
  )

  const handleEditUser = useCallback(
    (userId: number) => {
      const route = getRoute(routes.userDetails, { userId })
      navigate(route)
    },
    [navigate],
  )

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteUser,
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove user",
    })

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: handleEditUser,
      },
      {
        name: 'Delete',
        onClick: openDeleteModal,
      },
    ]
  }, [handleEditUser, openDeleteModal])

  const columnDefs = useMemo(() => {
    const defs = getUserColumnDefs(selectedTab).slice()
    if (selectedTab === UserTypeTab.TRAINEE || selectedTab === UserTypeTab.CAREGIVER) {
      const actionColumn = getActionColumn(actions)
      defs.push(actionColumn)
    }
    return defs
  }, [actions, selectedTab])

  useRefreshTable({ params: { selectedTab }, gridApi })

  const { csvLoading, onExportCSV } = useExportCSV(
    getUsersWrapper,
    gridApi,
    columnApi,
    selectedTab === UserTypeTab.TRAINEE ? csvTraineeFields : csvOrgUserFields,
    selectedTab === UserTypeTab.TRAINEE ? 'Trainees.csv' : 'Organizational Users.csv',
  )

  return (
    <>
      <TableToolbar
        left={left}
        exportCSVEnable={selectedTab === UserTypeTab.TRAINEE || selectedTab === UserTypeTab.ORG}
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />

      {(selectedTab === UserTypeTab.TRAINEE || selectedTab === UserTypeTab.CAREGIVER) && (
        <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
          <Box minWidth="200px">{CohortsFilter}</Box>
        </Stack>
      )}

      {selectedTab === UserTypeTab.ORG && (
        <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
          <Box minWidth="200px">{RoleFilter}</Box>
        </Stack>
      )}

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <ConfirmRemoveModal
        entityToRemove="User"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />
    </>
  )
}
