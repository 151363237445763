import { getActionColumn } from 'features/UI'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { getRoute } from 'utils'

export function useUserOrgTraineeColumns() {
  const navigate = useNavigate()

  const handleEditUser = useCallback(
    (userId: number) => {
      const route = getRoute(routes.orgUserDetails, { userId })
      navigate(route)
    },
    [navigate],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: handleEditUser,
      },
    ]
  }, [handleEditUser])

  return useMemo(
    () => [
      { headerName: 'Anonymous ID', field: 'anonymousId' },
      { headerName: 'First Name', field: 'firstname', filter: 'agTextColumnFilter' },
      { headerName: 'Last Name', field: 'lastname', filter: 'agTextColumnFilter' },
      { headerName: 'Email', field: 'email', filter: 'agTextColumnFilter' },
      { headerName: 'Cohort(s)', field: 'cohortNames' },
      { headerName: 'ORG/State/UNIT', field: 'organizationName', filter: 'agTextColumnFilter' },
      {
        headerName: 'Institution Access code',
        field: 'accessCodeCode',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Pathway', field: 'trainingPathwayTypeTitle' },
      { headerName: 'Access Code Expire Date', field: 'accessCodeExpiryDate' },
      { headerName: 'Access Code Effective Date', field: 'accessCodeEffectiveDate' },
      { headerName: 'Total Time Online', field: 'totalEngagement' },
      getActionColumn(actions),
    ],
    [actions],
  )
}
