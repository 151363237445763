import { Box, Stack, Tab, Tabs } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { getCohorts } from 'api/cohorts'
import {
  getCareArticlesEngagementPerUserReport,
  getCourseEngagementPerUserReport,
  getEngagementPerSessionReport,
  getLearnLibraryEngagementPerUserReport,
  getLessonEngagementPerUserReport,
  getQueryEngagementPerUserReport,
  getTotalEngagementPerUserReport,
} from 'api/reports'
import columnRenderers from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { createCohortsColumn, createCohortsCsvColumn } from 'features/Cohorts'
import { getActionColumn, RowAction, UIFilterAsyncAutocomplete } from 'features/UI'
import { CSVField, useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { useRefreshTable } from 'hooks/useRefreshTable'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import {
  BaseTableRequest,
  CareArticleType,
  EngagementPerSession,
  EngagementReportTypes,
  FilterModel,
  FilterModelNumberArray,
  Nullish,
  Option,
  User,
} from 'types'
import { formatDate, getRoute, useGetDataSource } from 'utils'
import { createEngagementColumn, createEngagementCsvColumn } from '../../helpers/engagementColumn'
import {
  createColumnExamAttempts,
  createCsvColumnExamAttempts,
} from '../../helpers/examAttemptsColumn'
import { createColumnPassedExam, createCsvColumnPassedExam } from '../../helpers/passedExamColumn'
import { prepareDateRangeParams } from '../../helpers/prepareDateRangeParams'
import { useDateRange } from '../../hooks/useDateRange'
import { DateRangeToolbar } from '../DateRangeToolbar/DateRangeToolbar'

const getAccessCodeValue = ({ data }: { data: User }) =>
  data?.licenseExpiredAt ? 'unlicensed' : data?.accessCode?.code

const activeColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  { headerName: 'Course Name', field: 'courseName', filter: 'agTextColumnFilter' },
  createEngagementColumn('Course Active Engagement', 'activeEngagement'),
  createColumnExamAttempts('Exam', 'completedCourses'),
  createColumnPassedExam(),
]

const lessonColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  { headerName: 'Course Name', field: 'courseName', filter: 'agTextColumnFilter' },
  { headerName: 'Lesson Name', field: 'lessonName', filter: 'agTextColumnFilter' },
  createEngagementColumn('Lesson active engagement', 'activeEngagement'),
  createColumnExamAttempts('Quiz', 'completedLessons'),
  createColumnPassedExam(),
]

const learnArticlesColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  createCohortsColumn('userCohorts'),
  { headerName: 'Learn Article Name', field: 'learnArticleName', filter: 'agTextColumnFilter' },
  { headerName: 'Learn Topic Name', field: 'learnTopicName', filter: 'agTextColumnFilter' },
  createEngagementColumn('Active Engagement', 'activeEngagement'),
]

const careArticlesColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Timeline Post Name',
    field: 'careArticleName',
    filter: 'agTextColumnFilter',
    minWidth: 220,
  },
  {
    headerName: 'Timeline Post Type',
    field: 'careArticleType',
    minWidth: 220,
    cellRenderer: (props: ICellRendererParams<{ careArticleType: CareArticleType }>) => {
      const careArticleType = props.data?.careArticleType
      return careArticleType === CareArticleType.Care ? 'Guide Post' : 'Action Post'
    },
  },
  createEngagementColumn('Active Engagement', 'activeEngagement'),
]

const queryColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  { headerName: 'Query Name', field: 'queryName', filter: 'agTextColumnFilter', minWidth: 220 },
  {
    headerName: 'Linked Article',
    field: 'learnArticleName',
    filter: 'agTextColumnFilter',
    minWidth: 220,
  },
  createEngagementColumn('Active Engagement', 'activeEngagement'),
]

const totalColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  {
    headerName: 'First Login',
    field: 'userFirstLoggedInAt',
    cellRenderer: columnRenderers.date('userFirstLoggedInAt', 'YYYY-MM-DD HH:mm:ss'),
  },
  {
    headerName: 'Last Login',
    field: 'userLastLoggedInAt',
    cellRenderer: columnRenderers.date('userLastLoggedInAt', 'YYYY-MM-DD HH:mm:ss'),
  },
  {
    headerName: 'Total Numbers of Login',
    field: 'userTotalNumbersOfLogin',
  },
  {
    headerName: 'Passed Courses',
    field: 'passedCoursesCount',
  },
  {
    headerName: 'Finished Lessons',
    field: 'passedLessonsCount',
  },
  createEngagementColumn('Learn Articles', 'learnArticleTime'),
  createEngagementColumn('Lesson', 'lessonTime'),
  createEngagementColumn('Quizzes', 'quizTime'),
  createEngagementColumn('TimeLine Posts', 'careArticleTime'),
  createEngagementColumn('Query', 'queryTime'),
  createEngagementColumn('Exams', 'questionnaireTime'),
  createEngagementColumn('Lessons Audio Time', 'lessonAudioTime'),
  createEngagementColumn('Learn Articles Audio Time', 'learnArticleAudioTime', { minWidth: 250 }),
  createEngagementColumn('Timeline Posts Audio Time', 'careArticleAudioTime', { minWidth: 250 }),
  createEngagementColumn('Total Time Online', 'totalEngagement'),
]

const sessionColumnDefs: ColDef[] = [
  { headerName: 'Anonymous ID', field: 'userAnonymousId', minWidth: 174 },
  { headerName: 'First Name', field: 'userFirstName', filter: 'agTextColumnFilter' },
  { headerName: 'Last Name', field: 'userLastName', filter: 'agTextColumnFilter' },
  { headerName: 'Login', field: 'userEmail', filter: 'agTextColumnFilter' },
  {
    headerName: 'Access Code',
    field: 'userAccessCode',
    filter: 'agTextColumnFilter',
    valueGetter: getAccessCodeValue,
  },
  createCohortsColumn('userCohorts'),
  {
    headerName: 'Login Date',
    field: 'startedAt',
    cellRenderer: columnRenderers.date('startedAt', 'YYYY-MM-DD HH:mm:ss'),
    minWidth: 200,
  },
  createEngagementColumn('Total Login Time', 'totalTime'),
]

const csvFieldsActive: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  createCohortsCsvColumn('userCohorts'),
  { label: 'Access Code', value: 'userAccessCode' },
  { label: 'Course Name', value: 'courseName' },
  createEngagementCsvColumn('Course Active Engagement', 'activeEngagement'),
  createCsvColumnExamAttempts('Exam attempts', 'completedCourses'),
  createCsvColumnPassedExam('completedCourses'),
]

const csvFieldsLesson: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  createCohortsCsvColumn('userCohorts'),
  { label: 'Access Code', value: 'userAccessCode' },
  { label: 'Lesson Name', value: 'lessonName' },
  { label: 'Course Name', value: 'courseName' },
  createEngagementCsvColumn('Active Engagement', 'activeEngagement'),
  createCsvColumnExamAttempts('Quiz attempts', 'completedLessons'),
  createCsvColumnPassedExam('completedLessons'),
]

const csvFieldsLearnArticles: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  {
    label: 'First Name',
    value: 'userFirstName',
  },
  {
    label: 'Last Name',
    value: 'userLastName',
  },
  {
    label: 'Login',
    value: 'userEmail',
  },
  {
    label: 'Access Code',
    value: 'userAccessCode',
  },
  createCohortsCsvColumn('userCohorts'),
  {
    label: 'Learn Article Name',
    value: 'learnArticleName',
  },
  {
    label: 'Learn Topic Name',
    value: 'learnTopicName',
  },
  createEngagementCsvColumn('Active Engagement', 'activeEngagement'),
]

const csvFieldsCareArticles: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  { label: 'Access Code', value: 'userAccessCode' },
  createCohortsCsvColumn('userCohorts'),
  { label: 'Timeline Post Name', value: 'careArticleName' },
  {
    label: 'Timeline Post Type',
    value: (data: Partial<{ careArticleType: CareArticleType }>) => {
      return data?.careArticleType === CareArticleType.Care ? 'Guide Post' : 'Action Post'
    },
  },
  createEngagementCsvColumn('Active Engagement', 'activeEngagement'),
]

const csvFieldsQuery: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  createCohortsCsvColumn('userCohorts'),
  { label: 'Access Code', value: 'userAccessCode' },
  { label: 'Query Name', value: 'queryName' },
  { label: 'Linked Article', value: 'learnArticleName' },
  createEngagementCsvColumn('Active Engagement', 'activeEngagement'),
]

const csvFieldsTotal: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  createCohortsCsvColumn('userCohorts'),
  { label: 'AccessCode', value: 'userAccessCode' },
  { label: 'Passed Courses', value: 'passedCoursesCount' },
  { label: 'Finished Lessons', value: 'passedLessonsCount' },
  createEngagementCsvColumn('Learn Articles', 'learnArticleTime'),
  createEngagementCsvColumn('Lesson', 'lessonTime'),
  createEngagementCsvColumn('Quizzes', 'quizTime'),
  createEngagementCsvColumn('TimeLine Posts', 'careArticleTime'),
  createEngagementCsvColumn('Query', 'queryTime'),
  createEngagementCsvColumn('Exams', 'questionnaireTime'),
  createEngagementCsvColumn('Lessons Audio Time', 'lessonAudioTime'),
  createEngagementCsvColumn('Learn Articles Audio Time', 'learnArticleAudioTime'),
  createEngagementCsvColumn('Timeline Posts Audio Time', 'careArticleAudioTime'),
  createEngagementCsvColumn('Total Time Online', 'totalEngagement'),
]

const csvFieldsSession: CSVField[] = [
  { label: 'Anonymous ID', value: 'userAnonymousId' },
  { label: 'First Name', value: 'userFirstName' },
  { label: 'Last Name', value: 'userLastName' },
  { label: 'Login', value: 'userEmail' },
  { label: 'Access Code', value: 'userAccessCode' },
  createCohortsCsvColumn('userCohorts'),
  {
    label: 'Login Date',
    value: (data: EngagementPerSession) => formatDate(data.startedAt, 'YYYY-MM-DD HH:mm:ss'),
  },
  createEngagementCsvColumn('Total Time Online', 'totalTime'),
]

type IReportsActions = {
  [key in EngagementReportTypes]: Nullish<RowAction[]>
}

type IReportsTabOptions = {
  [key in EngagementReportTypes]: {
    columns: ColDef[]
    title: string
    csvName: string
    csvFields: CSVField[]
    hasDateFilter: boolean
  }
}

const ReportsTabOptions: IReportsTabOptions = {
  [EngagementReportTypes.Course]: {
    title: 'Course Engagement per User',
    columns: activeColumnDefs,
    csvName: 'Course Engagement per User Report.csv',
    csvFields: csvFieldsActive,
    hasDateFilter: true,
  },
  [EngagementReportTypes.Lesson]: {
    title: 'Lesson Engagement per User',
    columns: lessonColumnDefs,
    csvName: 'Lesson Engagement per User Report.csv',
    csvFields: csvFieldsLesson,
    hasDateFilter: true,
  },
  [EngagementReportTypes.LearnLibrary]: {
    title: 'Learn Library Engagement per User',
    columns: learnArticlesColumnDefs,
    csvName: 'Learn Library Engagement Report.csv',
    csvFields: csvFieldsLearnArticles,
    hasDateFilter: true,
  },
  [EngagementReportTypes.CareArticle]: {
    title: 'Timeline Posts Engagement per User',
    columns: careArticlesColumnDefs,
    csvName: 'Timeline Posts Engagement Report.csv',
    csvFields: csvFieldsCareArticles,
    hasDateFilter: true,
  },
  [EngagementReportTypes.Query]: {
    title: 'Query Engagement Per User',
    columns: queryColumnDefs,
    csvName: 'Query Engagement Report.csv',
    csvFields: csvFieldsQuery,
    hasDateFilter: true,
  },
  [EngagementReportTypes.Total]: {
    title: 'Total Engagement Per User',
    columns: totalColumnDefs,
    csvName: 'User Total Engagement Report.csv',
    csvFields: csvFieldsTotal,
    hasDateFilter: true,
  },
  [EngagementReportTypes.Session]: {
    title: 'Login Report',
    columns: sessionColumnDefs,
    csvName: 'Login Report.csv',
    csvFields: csvFieldsSession,
    hasDateFilter: true,
  },
}

const ReportOrder = [
  EngagementReportTypes.Course,
  EngagementReportTypes.Lesson,
  EngagementReportTypes.LearnLibrary,
  EngagementReportTypes.CareArticle,
  EngagementReportTypes.Query,
  EngagementReportTypes.Total,
  EngagementReportTypes.Session,
]

export const ReportsEngagementUser = () => {
  const navigate = useNavigate()
  const { engagementType } = useParams()

  const selectedTab = useMemo(() => {
    if (!engagementType) return EngagementReportTypes.Course

    return engagementType as EngagementReportTypes
  }, [engagementType])

  const tabOptions = useMemo(() => ReportsTabOptions[selectedTab], [selectedTab])

  const handleChangeSelectedTab = useCallback(
    (_: React.SyntheticEvent, newValue: EngagementReportTypes) => {
      const route = getRoute(routes.reports_engagement_user, { engagementType: newValue })
      navigate(route)
    },
    [navigate],
  )

  const [cohortsFilterValue, setCohortsFilterValue] = useState<Option[]>([])

  const handleDetailsClick = useCallback(
    (sessionUuid: number) => {
      const route = getRoute(routes.reports_engagement_user_session, { sessionUuid })
      navigate(route)
    },
    [navigate],
  )

  const actions: IReportsActions = useMemo(() => {
    return {
      [EngagementReportTypes.Course]: null,
      [EngagementReportTypes.Lesson]: null,
      [EngagementReportTypes.LearnLibrary]: null,
      [EngagementReportTypes.CareArticle]: null,
      [EngagementReportTypes.Query]: null,
      [EngagementReportTypes.Total]: null,
      [EngagementReportTypes.Session]: [{ name: 'See details', onClick: handleDetailsClick }],
    }
  }, [handleDetailsClick])

  const columns = useMemo(() => {
    const tabActions = actions[selectedTab]
    if (!tabActions) {
      return tabOptions.columns
    }

    return [...tabOptions.columns, getActionColumn(tabActions, 'sessionUuid')]
  }, [actions, selectedTab, tabOptions.columns])

  const {
    rangeType,
    year,
    month,
    selectedDate,
    dateInterval,
    onChangeDateInterval,
    onChangeRange,
    onChangeYear,
    onChangeMonth,
    setSelectedDate,
  } = useDateRange()

  const getActiveEngagementWrapper = useCallback(
    (req: BaseTableRequest) => {
      const filter: FilterModel = {
        ...(req.filter || {}),
      }

      if (cohortsFilterValue.length > 0) {
        filter.cohortId = {
          type: 'number',
          op: 'in',
          value: cohortsFilterValue.map((cohort) => cohort.value),
        } as FilterModelNumberArray
      }

      const request = { ...req, filter }

      const dateParams = prepareDateRangeParams(rangeType, year, month, selectedDate, dateInterval)

      if (selectedTab === EngagementReportTypes.Course) {
        return getCourseEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.Lesson) {
        return getLessonEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.LearnLibrary) {
        return getLearnLibraryEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.CareArticle) {
        return getCareArticlesEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.Query) {
        return getQueryEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.Total) {
        return getTotalEngagementPerUserReport({ ...request, params: dateParams })
      }

      if (selectedTab === EngagementReportTypes.Session) {
        return getEngagementPerSessionReport({ ...request, params: dateParams })
      }

      return getTotalEngagementPerUserReport({ ...request, params: dateParams })
    },
    [cohortsFilterValue, dateInterval, month, rangeType, selectedDate, selectedTab, year],
  )

  const getDataSourceActive = useGetDataSource(getActiveEngagementWrapper)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSourceActive)

  const { csvLoading, onExportCSV } = useExportCSV(
    getActiveEngagementWrapper,
    gridApi,
    columnApi,
    tabOptions.csvFields,
    tabOptions.csvName,
  )

  useRefreshTable({ params: { rangeType, year, month, selectedDate }, gridApi })

  const handleChangeCohortFilter = useCallback(
    (newValue: Option[]) => {
      setCohortsFilterValue(newValue)
      gridApi?.refreshServerSide()
    },
    [gridApi],
  )

  return (
    <>
      <TableToolbar
        left={
          <Tabs
            value={engagementType}
            onChange={handleChangeSelectedTab}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons
            sx={{ ml: '-24px' }}
          >
            {ReportOrder.map((type) => (
              <Tab key={type} value={type} label={getTitle(ReportsTabOptions[type].title)} />
            ))}
          </Tabs>
        }
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      />

      <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
        {tabOptions.hasDateFilter && (
          <DateRangeToolbar
            rangeType={rangeType}
            year={year}
            month={month}
            selectedDate={selectedDate}
            dateInterval={dateInterval}
            onChangeDateInterval={onChangeDateInterval}
            onChangeRange={onChangeRange}
            onChangeYear={onChangeYear}
            onChangeMonth={onChangeMonth}
            setSelectedDate={setSelectedDate}
          />
        )}

        <Box minWidth="200px">
          <UIFilterAsyncAutocomplete
            label="Cohorts"
            value={cohortsFilterValue}
            onChange={handleChangeCohortFilter}
            queryId="cohorts"
            apiRequest={getCohorts}
          />
        </Box>
      </Stack>

      <InfinityAgGrid columnDefs={columns} pagination onGridReady={onGridReady} />
    </>
  )
}
