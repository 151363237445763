export enum MetricTimerStatus {
  Created = 'Created',
  InProgress = 'InProgress',
  Ready = 'Ready',
}

export class MetricTimer {
  private idleTimer?: ReturnType<typeof setInterval> = undefined
  private readonly idleLimitMin: number
  private idleTimerValue = 0
  public hasIdleTimer: boolean = true
  public dateFrom: number = Date.now()
  public status: MetricTimerStatus = MetricTimerStatus.Created
  public onReady: () => void

  constructor({
    onReady,
    hasIdleTimer = false,
    idleLimitMin = 2,
  }: {
    onReady: () => void
    hasIdleTimer?: boolean
    idleLimitMin?: number
  }) {
    this.hasIdleTimer = hasIdleTimer
    this.idleLimitMin = idleLimitMin
    this.onReady = onReady
    this.create()
  }

  private create() {
    this.dateFrom = Date.now()
    this.status = MetricTimerStatus.Created

    if (this.hasIdleTimer) {
      this.setIdleTimer()
      window.addEventListener('scroll', this.resetIdleTimer, true)
      window.addEventListener('click', this.resetIdleTimer)
    }
  }

  private setIdleTimer() {
    this.idleTimerValue = 0
    this.idleTimer = setInterval(() => {
      this.idleTimerValue++
      if (this.idleTimerValue >= this.idleLimitMin) {
        this.ready()
        this.clearIdleTimer()
      }
    }, 60 * 1000)
  }

  private clearIdleTimer() {
    if (!this.idleTimer || !this.hasIdleTimer) {
      return
    }
    this.idleTimerValue = 0
    clearInterval(this.idleTimer)
  }

  private resetIdleTimer = () => {
    if (this.status === MetricTimerStatus.Ready) {
      this.clearIdleTimer()
      this.setIdleTimer()
      this.start()
    }
  }

  public setReadyCallback = (callback: () => void) => {
    this.onReady = callback
  }

  public start() {
    this.status = MetricTimerStatus.InProgress
    this.dateFrom = Date.now()
  }

  public restart() {
    this.clearIdleTimer()
    this.create()
    this.start()
  }

  public ready() {
    this.status = MetricTimerStatus.Ready
    this.onReady()
  }

  public destroy() {
    this.status = MetricTimerStatus.Ready
    this.clearIdleTimer()
    if (this.hasIdleTimer) {
      window.removeEventListener('scroll', this.resetIdleTimer, true)
      window.removeEventListener('click', this.resetIdleTimer)
    }
  }
}
