import { useMemo } from 'react'
import { CSVField } from 'hooks/useExportCSV'
import { formatDate } from 'utils'
import { csvColumnCreatedBy, csvColumnUpdatedBy } from 'features/UI'
import { PLACEHOLDER } from 'components/column-renderers'

export function useQueryCSVField() {
  return useMemo<CSVField[]>(
    () => [
      {
        label: 'Question name',
        value: 'name',
      },
      {
        label: 'Question Count',
        value: 'questionsCount',
      },
      {
        label: 'Linked Learn library Article',
        value: 'learnArticleName',
      },
      {
        label: 'Published',
        value: ({ isPublished }) => (isPublished ? 'Published' : 'Unpublished'),
      },
      {
        label: 'Questions',
        value: ({ questions }) =>
          questions.map((q: { name: string }) => q.name).join(', ') || PLACEHOLDER,
      },
      {
        label: 'Creation Date',
        value: ({ createdAt }) => formatDate(createdAt),
      },
      csvColumnCreatedBy(),
      {
        label: 'Last Edited',
        value: ({ updatedAt }) => formatDate(updatedAt),
      },
      csvColumnUpdatedBy(),
    ],
    [],
  )
}
