import MuiAppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import { NAV_DRAWER_WIDTH } from 'const'

export const LayoutBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#fff',
  borderBottom: '1px solid #e0e0e0',
  color: 'black',
  width: `100%`,
  marginLeft: `${NAV_DRAWER_WIDTH}px`,
}))
