import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useActions } from 'store/hooks'
import { LayoutHeaderMenu } from '../../../features/Layout/components/LayoutHeaderMenu/LayoutHeaderMenu'
import { useAuthContext } from '../../context/AuthProvider'
import AdminProfileInfo from '../admin-profile/AdminProfileInfo'

export const AdminHeaderContent = () => {
  const { logout } = useActions()

  const { clearUserData } = useAuthContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate(routes.signIn)
    clearUserData()
  }

  return (
    <>
      <LayoutHeaderMenu />

      <AdminProfileInfo handleLogout={handleLogout} />
    </>
  )
}
