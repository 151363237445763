import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED } from 'const'
import moment from 'moment/moment'
import { AccessCode, EAccessCodeType, Nullish } from 'types'
import { any, coerce, literal, object, string, TypeOf } from 'zod'

const organizationValidationText = 'The identifier of organization must contain 2 digits'
const specificUnitIDValidationText =
  'The identifier of specific unit must contain 2 characters (letter and/or digit)'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isExpiryLaterThatEffective = (data: any) => {
  if (!moment(data.expiryDate).isValid() || !moment(data.effectiveDate).isValid()) {
    return true
  }
  return moment(data.expiryDate).isAfter(moment(data.effectiveDate))
}

const commonSchema = {
  type: string().min(1, FIELD_IS_REQUIRED),
  reason: string(),
  effectiveDate: any().refine(Boolean, FIELD_IS_REQUIRED),
  expiryDate: any().refine(Boolean, FIELD_IS_REQUIRED),
  numberOfUsers: coerce.number().min(1, FIELD_IS_REQUIRED),
}

const usersSchema = {
  ownerFirstName: string().min(1, FIELD_IS_REQUIRED),
  ownerLastName: string().min(1, FIELD_IS_REQUIRED),
  ownerEmail: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
  regionalFirstName: string().optional(),
  regionalLastName: string().optional(),
  regionalEmail: string().email(EMAIL_IS_INVALID).optional().or(literal('')),
  facilityFirstName: string().optional(),
  facilityLastName: string().optional(),
  facilityEmail: string().email(EMAIL_IS_INVALID).optional().or(literal('')),
  supervisorFirstName: string().min(1, FIELD_IS_REQUIRED),
  supervisorLastName: string().min(1, FIELD_IS_REQUIRED),
  supervisorEmail: string().min(1, FIELD_IS_REQUIRED).email(EMAIL_IS_INVALID),
}

const institutionalSchema = {
  organizationName: string().min(1, FIELD_IS_REQUIRED),
  organizationType: string().min(1, FIELD_IS_REQUIRED),
  twoDigitsOrg: string()
    .min(1, FIELD_IS_REQUIRED)
    .max(2, organizationValidationText)
    .refine((val) => /\d\d/.test(val), organizationValidationText),
  state: string().min(1, FIELD_IS_REQUIRED),
  specificUnitName: string().min(1, FIELD_IS_REQUIRED),
  twoDigitsSpecific: string()
    .min(1, FIELD_IS_REQUIRED)
    .max(2, specificUnitIDValidationText)
    .refine((val) => /[0-9a-z]{2}/i.test(val), specificUnitIDValidationText),
  duration: string().min(1, FIELD_IS_REQUIRED),
  numberOfUsers: coerce.number().min(1, FIELD_IS_REQUIRED),
}

const formSchema = (codeType: EAccessCodeType, isEdit: boolean) => {
  let schema = {
    ...commonSchema,
    ...({} as typeof institutionalSchema),
    ...({} as typeof usersSchema),
  }

  if (codeType === EAccessCodeType.institutionAccessCode) {
    schema = {
      ...schema,
      ...institutionalSchema,
    }

    if (!isEdit) {
      schema = {
        ...schema,
        ...usersSchema,
      }
    }
  }

  return object(schema)
    .refine(isExpiryLaterThatEffective, {
      path: ['expiryDate'],
      message: 'Expiry date must be later that Effective date',
    })
    .refine(
      (data) => {
        if (data.type === EAccessCodeType.promoCode) {
          return true
        }

        const formatRegex = new RegExp(/^\s*(\d+y\s+)?(\d+m\s+)?(\d+d\s*)?$/)
        return formatRegex.test(data.duration)
      },
      {
        path: ['duration'],
        message: 'Wrong duration format',
      },
    )
}

export const formSettings = (codeType: EAccessCodeType, editedItem: Nullish<AccessCode>) => ({
  mode: 'onChange' as const,
  resolver: zodResolver(formSchema(codeType, !!editedItem)),
  defaultValues: !editedItem
    ? {
        type: codeType,
        reason: '',
        organizationName: '',
        twoDigitsOrg: '',
        effectiveDate: null,
        expiryDate: null,
        organizationType: '',
        state: '',
        specificUnitName: '',
        twoDigitsSpecific: '',
        duration: '',
        numberOfUsers: 1,
      }
    : {
        ...editedItem,
        twoDigitsSpecific: editedItem.specificTwoDigits,
        state: editedItem.specificState,
        type: codeType,
        organizationName: editedItem.organization?.name || '',
        twoDigitsOrg: editedItem.organization?.twoDigits || '',
        organizationType: editedItem.organization?.type || '',
      },
})

export type FormType = TypeOf<ReturnType<typeof formSchema>>
