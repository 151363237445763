import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import iconSmQuestion from 'images/icons/icon_sm_question.png'
import { TipDrawerView } from '../TipDrawerView/TipDrawerView'
import { IconContainer, StackContainer } from './TipOrgGroup.styled'
import { useShowControl } from 'hooks/useShowControl'
import { Tip } from 'types'

interface Props {
  tip: Tip
}

export const TipOrgGroup = ({ tip }: Props) => {
  const [isOpen, handleOpen, handleClose] = useShowControl()

  return (
    <>
      {isOpen && <TipDrawerView id={tip.id} isOpen={isOpen} handleClose={handleClose} />}

      <StackContainer>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          onClick={handleOpen}
        >
          <IconContainer>
            <img src={tip.bannerUrl || iconSmQuestion} />
          </IconContainer>
          <Stack flex={1}>
            <Typography flex={1} fontWeight={400} variant="h6">
              {tip.name}
            </Typography>
          </Stack>
        </Stack>
      </StackContainer>
    </>
  )
}
