import { useEffect } from 'react'
import { Stack } from '@mui/material'
import Breadcrumbs from 'components/Breadcumbs'
import NotificationSys from 'components/NotificationSystem'
import PageTitle from 'components/page/PageTitle'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { UIControlChangeNameAndIcon, UIEntityItemArticle } from 'features/UI'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Tip } from 'types'
import { useSearchParamsObject } from 'utils'
import { getTipById, updateTip } from 'api/tips'

const getPath = (data: Tip) => {
  const tipName = data.name

  return [
    {
      href: routes.manageTips,
      text: 'Tips',
    },
    {
      text: tipName,
    },
  ]
}

export const TipEditPage = () => {
  const { id } = useSearchParamsObject()
  const { data, setData, loading, setLoading } = useEntityDataControl({
    id,
    loadFunc: getTipById,
    warning: "Can't load tip",
  })

  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (state?.notification) {
      NotificationSys.showSuccess(state.notification)
    }
  }, [state])

  const onEditMedia = () => {
    const id = data?.id || ''
    navigate(`${routes.lessonContentEdit}?id=${id}&type=tip`)
  }

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return (
      <FailPlaceholder
        error="Can't load data of lesson"
        link={routes.courses}
        linkText="Back to courses table"
      />
    )
  }

  const path = getPath(data)

  return (
    <>
      <Stack spacing={2}>
        <PageTitle>Tip View</PageTitle>

        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs path={path} />

            <UIControlChangeNameAndIcon<Tip>
              entityName="Tip"
              data={data}
              setData={setData}
              loading={loading}
              setLoading={setLoading}
              updateRequest={updateTip}
            />
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <UIEntityItemArticle
            onEdit={onEditMedia}
            text="Tip Article"
            article={data.article}
            hasAudio={data.hasAudio}
          />
        </Stack>
      </Stack>
    </>
  )
}
