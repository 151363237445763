import { Button, Tab, Tabs } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { createCode, getCodes, updateCode } from 'api/accessCode'
import columnRenderers, { PLACEHOLDER } from 'components/column-renderers'
import NotificationSys from 'components/NotificationSystem'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { getActionColumn } from 'features/UI'
import { useGridControl } from 'hooks/useGridControl'
import { useShowControl } from 'hooks/useShowControl'
import { useTabsControl } from 'hooks/useTabsControl'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { AccessCode, BaseTableRequest, EAccessCodeType, Nullish } from 'types'
import { useGetDataSource } from 'utils'
import { AccessCodeGenerationModal } from '../AccessCodeGenerationModal/AccessCodeGenerationModal'
import { AccessManagementColumnCode } from '../AccessManagementColumnCode/AccessManagementColumnCode'
import { AccessManagementColumnCodeType } from '../AccessManagementColumnCodeType/AccessManagementColumnCodeType'
import { useAccessCodeRowStyle } from 'features/AccessManagement/hooks/getAccessCodeRowStyle'

export const accessManagementColumns = {
  codeType: {
    headerName: 'Code Type',
    field: 'codeType',
    minWidth: 220,
    cellRenderer: AccessManagementColumnCodeType,
  },
  codeValue: {
    headerName: 'Code Value',
    cellRenderer: AccessManagementColumnCode,
    colId: 'code',
    minWidth: 140,
  },
  effectiveDate: {
    headerName: 'Effective Date',
    cellRenderer: columnRenderers.date('effectiveDate'),
    minWidth: 270,
    colId: 'effectiveDate',
  },
  expiryDate: {
    headerName: 'Expiry Date',
    cellRenderer: columnRenderers.date('expiryDate'),
    minWidth: 240,
    colId: 'expiryDate',
  },
  numberOfUsers: { headerName: 'Number of Users', field: 'numberOfUsers', minWidth: 200 },
  codeCanBeUsed: {
    headerName: 'Code Can be Used',
    valueGetter: (v) => (v.data ? v.data.numberOfUsers - v.data.timesUsed : '-'),
    sortable: false,
  },
  timesUsed: { headerName: 'Code Used', field: 'timesUsed' },
  reason: { headerName: 'Reason for Code', field: 'reason', filter: 'agTextColumnFilter' },
  organizationName: {
    headerName: 'Organization Name',
    field: 'organizationName',
    minWidth: 220,
    filter: 'agTextColumnFilter',
    valueGetter: (value) => {
      if (!value.data) return PLACEHOLDER
      if (value.data.organizationName) {
        return value.data.organizationName
      }

      return value.data.organization?.name || PLACEHOLDER
    },
  },
  twoDigitsOrg: {
    headerName: 'Two-Digit Organization Number',
    field: 'twoDigitsOrg',
    minWidth: 300,
    valueGetter: (value) => {
      if (!value.data) return PLACEHOLDER
      if (value.data.twoDigitsOrg) {
        return value.data.twoDigitsOrg
      }

      return value.data.organization?.twoDigits || PLACEHOLDER
    },
  },
  organizationType: {
    headerName: 'Organization Type',
    field: 'organizationType',
    minWidth: 220,
    filter: 'agTextColumnFilter',
    valueGetter: (value) => {
      if (!value.data) return PLACEHOLDER
      if (value.data.organizationType) {
        return value.data.organizationType
      }

      return value.data.organization?.type || PLACEHOLDER
    },
  },
  state: {
    headerName: 'Two-Letter State Location of Trainees',
    field: 'state',
    minWidth: 360,
    valueGetter: (value) => {
      if (!value.data) return PLACEHOLDER
      return value.data.state || value.data.specificState || PLACEHOLDER
    },
  },
  specificUnitName: {
    headerName: 'Specific Unit Name',
    field: 'specificUnitName',
    minWidth: 260,
    filter: 'agTextColumnFilter',
  },
  twoDigitsSpecific: {
    headerName: 'Two-Digit Specific Unit Letter / Number',
    field: 'specificTwoDigits',
    minWidth: 360,
  },
  duration: { headerName: 'Access Code Duration', field: 'duration', minWidth: 240 },
} satisfies Record<string, ColDef>

const institutionalCodeColumns: ColDef[] = [
  accessManagementColumns.codeType,
  accessManagementColumns.codeValue,
  accessManagementColumns.effectiveDate,
  accessManagementColumns.expiryDate,
  accessManagementColumns.numberOfUsers,
  accessManagementColumns.codeCanBeUsed,
  accessManagementColumns.timesUsed,
  accessManagementColumns.reason,
  accessManagementColumns.organizationName,
  accessManagementColumns.twoDigitsOrg,
  accessManagementColumns.organizationType,
  accessManagementColumns.state,
  accessManagementColumns.specificUnitName,
  accessManagementColumns.twoDigitsSpecific,
  accessManagementColumns.duration,
]

const promoCodeColumns: ColDef[] = [
  accessManagementColumns.codeType,
  accessManagementColumns.codeValue,
  accessManagementColumns.timesUsed,
  accessManagementColumns.codeCanBeUsed,
  accessManagementColumns.reason,
  accessManagementColumns.effectiveDate,
  accessManagementColumns.expiryDate,
  accessManagementColumns.numberOfUsers,
]

export const AccessManagement = () => {
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<EAccessCodeType>(
    EAccessCodeType.institutionAccessCode,
  )

  const [isOpenGenerate, handleOpenGenerate, handleCloseGenerate] = useShowControl()
  const [isEdit, handleOpenEdit, handleCloseEdit] = useShowControl()
  const [editedItem, setEditedItem] = useState<Nullish<AccessCode>>(null)

  const handleClickEdit = useCallback(
    (_: number, item: ICellRendererParams<AccessCode>) => {
      if (!item.data) return

      setEditedItem(item.data)
      handleOpenEdit()
    },
    [handleOpenEdit],
  )

  const columns = useMemo(() => {
    if (selectedTab === EAccessCodeType.institutionAccessCode) {
      return institutionalCodeColumns.concat(
        getActionColumn([
          {
            name: 'Edit',
            onClick: handleClickEdit,
          },
        ]),
      )
    }

    if (selectedTab === EAccessCodeType.promoCode) {
      return promoCodeColumns
    }

    return []
  }, [handleClickEdit, selectedTab])

  const getCodeWrapper = useCallback(
    (request: BaseTableRequest) => {
      return getCodes({
        ...request,
        filter: {
          ...request.filter,
          codeType: {
            type: 'text',
            op: 'equals',
            value: selectedTab,
          },
        },
      })
    },
    [selectedTab],
  )

  const getDataSource = useGetDataSource(getCodeWrapper)
  const { gridApi, onGridReady } = useGridControl(getDataSource)

  const mutation = useMutation(createCode, {
    onSuccess: (data) => {
      handleCloseGenerate()
      gridApi?.purgeInfiniteCache()
      NotificationSys.showSuccess(`Created access code ${data.data.code}`)
    },
  })

  const onCloseEdit = useCallback(() => {
    setEditedItem(null)
    handleCloseEdit()
  }, [handleCloseEdit])

  const updateMutation = useMutation(updateCode, {
    onSuccess: (data) => {
      onCloseEdit()
      gridApi?.purgeInfiniteCache()
      NotificationSys.showSuccess(`Updated access code ${data.data.code}`)
    },
  })

  const getRowStyle = useAccessCodeRowStyle()

  const left = (
    <>
      <Tabs value={selectedTab} onChange={handleChangeSelectedTab} indicatorColor="primary">
        <Tab
          value={EAccessCodeType.institutionAccessCode}
          label={getTitle('Institutional Access Codes')}
        />
        <Tab value={EAccessCodeType.promoCode} label={getTitle('Promo Codes')} />
      </Tabs>
    </>
  )

  return (
    <>
      <TableToolbar left={left}>
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={handleOpenGenerate}>
          Generate Code
        </Button>
      </TableToolbar>

      <InfinityAgGrid
        pagination
        columnDefs={columns}
        getRowStyle={getRowStyle}
        onGridReady={onGridReady}
      />

      <AccessCodeGenerationModal
        loading={mutation.isLoading}
        isOpen={isOpenGenerate}
        handleClose={handleCloseGenerate}
        onSubmit={mutation.mutate}
        type={selectedTab}
        editedItem={null}
      />

      <AccessCodeGenerationModal
        loading={updateMutation.isLoading}
        isOpen={isEdit}
        handleClose={onCloseEdit}
        onSubmit={updateMutation.mutate}
        type={selectedTab}
        editedItem={editedItem}
      />
    </>
  )
}
