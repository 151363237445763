import { Box, Button, Stack } from '@mui/material'
import { UIAudioPlayer } from 'features/UI'
import React, { useState } from 'react'
import { IMetricScope, IMetricType } from 'types'
import { AudioLanguages } from '../../models/Article.model'

export function ArticleAudioUserView({
  enMediaUrl,
  enMediaFileMimeType,
  esMediaUrl,
  esMediaFileMimeType,
  metricType,
  metricEntityId,
  onSaveMetric,
}: {
  enMediaUrl: string | null
  enMediaFileMimeType?: string
  esMediaUrl: string | null
  esMediaFileMimeType?: string
  metricType?: IMetricType
  metricEntityId?: number
  onSaveMetric?: (value: number, uuid: string, scope: IMetricScope) => void
}) {
  const [language, setLanguage] = useState(() => {
    if (enMediaUrl) {
      return AudioLanguages.EN
    } else if (esMediaUrl) {
      return AudioLanguages.ES
    } else {
      return null
    }
  })

  const filesUrls = {
    [AudioLanguages.EN]: enMediaUrl,
    [AudioLanguages.ES]: esMediaUrl,
  }

  const handleChangeLanguage = (lang: AudioLanguages) => () => {
    setLanguage(lang)
  }

  if (
    (filesUrls[AudioLanguages.EN] === null && filesUrls[AudioLanguages.ES] === null) ||
    !language
  ) {
    return null
  }

  return (
    <Stack flexDirection="row" flexWrap="wrap" mt={3} mb={1} width="100%" className="gap-8">
      {enMediaUrl && (
        <Button
          size="small"
          color="secondary"
          variant={language === AudioLanguages.EN ? 'contained' : 'outlined'}
          onClick={handleChangeLanguage(AudioLanguages.EN)}
        >
          EN
        </Button>
      )}
      {esMediaUrl && (
        <Button
          size="small"
          color="secondary"
          variant={language === AudioLanguages.ES ? 'contained' : 'outlined'}
          onClick={handleChangeLanguage(AudioLanguages.ES)}
        >
          ES
        </Button>
      )}

      <Box flexGrow={1}>
        <UIAudioPlayer
          key={filesUrls[language]}
          src={filesUrls[language] || ''}
          mediaFileMimeType={
            language === AudioLanguages.EN ? enMediaFileMimeType : esMediaFileMimeType
          }
          metricType={metricType}
          metricEntityId={metricEntityId}
          onSaveMetric={onSaveMetric}
        />
      </Box>
    </Stack>
  )
}
