import { Box } from '@mui/material'
import DrawerHeaderFiller from 'components/drawer/common/DrawerHeaderFiller'
import { NavigationDrawer } from 'components/drawer/navigation/NavigationDrawer'
import { AdminHeaderContent } from 'components/page/components/AdminHeaderContent'
import { ADMIN_NAV_DRAWER_WIDTH } from 'const'
import { useCheckAuthorizationAndRedirect } from 'hooks/useCheckAuthorizationAndRedirect'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { UserType } from 'types'
import { OrganizationDrawerContent } from '../../../Organization/components/OrganizationDrawerContent/OrganizationDrawerContent'
import { LayoutHeader } from '../LayoutHeader/LayoutHeader'
import { LayoutMain } from '../LayoutMain/LayoutMain'

export function LayoutOrganizationPage() {
  const redirect = useCheckAuthorizationAndRedirect([
    UserType.ORG_OWNER,
    UserType.ORG_SUPERVISOR,
    UserType.ORG_FACILITY_MANAGER,
    UserType.ORG_REGIONAL_MANAGER,
  ])
  if (redirect) {
    return <>{redirect}</>
  }

  return (
    <Box display="flex">
      <LayoutHeader>
        <AdminHeaderContent />
      </LayoutHeader>

      <NavigationDrawer width={ADMIN_NAV_DRAWER_WIDTH}>
        <OrganizationDrawerContent />
      </NavigationDrawer>

      <LayoutMain>
        <DrawerHeaderFiller />

        <Box
          paddingX={2}
          paddingTop={2}
          flexGrow={1}
          className="ag-theme-alpine"
          display="flex"
          flexDirection="column"
          style={{ fontSize: '1rem' }}
        >
          <Outlet />
        </Box>
      </LayoutMain>
    </Box>
  )
}
