import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
import { Stack } from '@mui/material'
import { updateCareArticle } from 'api/careArticles'
import { updateLibraryArticle } from 'api/learnArticles'
import { updateLesson } from 'api/lessons'
import { createMediaFile } from 'api/mediaFiles'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { DataProp } from 'editorjs-blocks-react-renderer'
import { MetricText } from 'features/Metric/components/MetricText/MetricText'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import React, { useRef, useState } from 'react'
import { routes } from 'routes/routes'
import {
  CareArticle,
  EditorMode,
  LearnArticle,
  Lesson,
  PartialNull,
  RequestById,
  Response,
  Tip,
} from 'types'
import { useSearchParamsObject } from 'utils'
import { ArticleOptionsByType, ContentType } from '../../models/Article.model'
import { EditorAPI } from '../../models/ArticleEditor.model'
import { ArticleEditor } from '../ArticleEditor/ArticleEditor'
import { ArticleEditorToolbar } from '../ArticleEditorToolbar/ArticleEditorToolbar'
import { ArticleNote } from '../ArticleNote/ArticleNote'
import { ArticlePreviewModal } from '../ArticlePreviewModal/ArticlePreviewModal'
import { updateTip } from 'api/tips'

export function ArticleEditorPage() {
  const { id, type } = useSearchParamsObject()
  const entityType = (type || 'lesson') as ContentType

  const { data, setLoading, setData, loading } = useEntityDataControl({
    id,
    loadFunc: ArticleOptionsByType[entityType].loadFunc as (
      request: RequestById,
    ) => Promise<Response<Lesson | LearnArticle>>,
    warning: "Can't load data of article",
  })
  const [note, setNote] = useState('')
  const [previewIsOpen, setPreviewIsOpen] = useState(false)
  const [previewData, setPreviewData] = useState<DataProp | null>(null)

  const ref = useRef<EditorAPI | null>(null)
  const [preview, setPreview] = useState<React.ReactNode>(null)

  const editorMode = preview ? EditorMode.Preview : EditorMode.Edit

  const onClickPreview = async () => {
    if (!ref.current) {
      return
    }

    if (preview) {
      setPreview(null)
      return
    }

    const data = await ref.current.getData()
    setPreviewData(data as DataProp)
    setPreviewIsOpen(true)
  }

  const prepareMediaFiles = async (data: OutputData) => {
    const mediaFilesToUpload = data.blocks.filter((block) => {
      if (block.type === 'image' && block.data.file.file) {
        return true
      }

      return block.type === 'imageAndText' && block.data.file
    })

    const mediaFilesPromises = mediaFilesToUpload.map((block) => {
      const file = block.type === 'image' ? block.data.file.file : block.data.file
      return createMediaFile(file, () => {})
    })

    const mediaFilesResponses = await Promise.all(mediaFilesPromises)

    const mediaBlocks = mediaFilesResponses.map((response, index) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const block: any = mediaFilesToUpload[index]
      if (block.type === 'image') {
        return {
          ...block,
          data: {
            ...block.data,
            file: {
              mediaId: response.data.id,
              url: response.data.url,
            },
          },
        }
      }

      return {
        ...block,
        data: {
          description: block.data.description,
          photo: response.data.url,
          mediaId: response.data.id,
        },
      }
    })

    const blocks = data.blocks.map((block) => {
      const newBlock = mediaBlocks.find((mediaBlock) => mediaBlock.id === block.id)
      return newBlock || block
    })

    return blocks
  }

  const onClickSave = async () => {
    if (!ref.current) {
      return
    }

    const articleData = await ref.current.getData()
    const blocks = await prepareMediaFiles(articleData)

    const request = {
      ...data,
      article: {
        ...articleData,
        blocks,
      },
      note,
    }

    try {
      setLoading(true)
      let response
      if (entityType === 'lesson') {
        response = await updateLesson(request as PartialNull<Lesson>)
      } else if (entityType === 'learn') {
        response = await updateLibraryArticle(request as PartialNull<LearnArticle>)
      } else if (entityType === 'care') {
        response = await updateCareArticle(request as PartialNull<CareArticle>)
      } else if (entityType === 'tip') {
        response = await updateTip(request as PartialNull<Tip>)
      }
      if (response) {
        setData(response.data as Lesson | LearnArticle)
      }
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return (
      <FailPlaceholder
        error="Can't load data of article content"
        link={routes.courses}
        linkText="Back to courses table"
      />
    )
  }

  const handleClose = () => {
    setPreviewIsOpen(false)
  }

  return (
    <Stack spacing={2} flexGrow={1} paddingBottom={2} minWidth="100%" alignItems="center">
      <ArticleEditorToolbar
        mode={editorMode}
        entityType={entityType}
        onClickPreview={onClickPreview}
        onClickSave={onClickSave}
        lessonId={data.id}
        lessonName={data.name}
        data={data}
        setData={setData}
      />

      <MetricText
        wordCount={data.wordCount}
        talkTime={data.talkTime}
        silentReadingTime={data.silentReadingTime}
        fleschReadingEase={data.fleschReadingEase}
        fryReadabilityGradeLevel={data.fryReadabilityGradeLevel}
        inArticle
      />

      <ArticleNote note={note || data.note || ''} onChange={setNote} />

      <ArticleEditor ref={ref} data={data?.article} />

      <ArticlePreviewModal
        isOpen={previewIsOpen}
        data={previewData}
        onClose={handleClose}
        lessonName={data.name}
      />
    </Stack>
  )
}
