import React from 'react'
import { LayoutHeaderMenu } from '../../../features/Layout/components/LayoutHeaderMenu/LayoutHeaderMenu'
import { useDrawersContext } from '../../context/ClientsDrawersProvider'
import ProfileInfo from '../client-profile/ProfileInfo'

export const ClientsHeaderContent = () => {
  const { handleOpenAboutUsDrawer } = useDrawersContext()

  return (
    <>
      <LayoutHeaderMenu onLogoClick={handleOpenAboutUsDrawer} />

      <ProfileInfo />
    </>
  )
}
