import { useParams } from 'react-router-dom'
import { UserOrganizationDetails } from './UserOrganizationDetails'

export function UserOrganizationDetailsPage() {
  const { userId } = useParams()

  if (!userId) {
    return null
  }

  return <UserOrganizationDetails userId={Number(userId)} />
}
