import { addTotalEngagementMetric } from 'api/metrics'
import { SESSION_IS_EXPIRED } from 'const'
import debounce from 'lodash/debounce'
import { router } from 'routes/router'
import { routes } from 'routes/routes'
import { User } from 'types'
import { clearLocalStorage, formatMetricDate, getFromLocalStorage, USER_DATA } from 'utils'
import NotificationSys from '../NotificationSystem'

const ONE_MIN = 60000
const MAX_SESSION_DURATION = 15 // minutes
const PERIOD_INTERVAL = 3

class Timer {
  userSessionFrom: string | null
  idleIntervalId: NodeJS.Timer | null = null
  idleTime: number = 0
  periodCount: number = 0
  isSessionActive: boolean = false

  constructor() {
    this.userSessionFrom = formatMetricDate()
    this.startIdleTimer()

    window.addEventListener('mousemove', debounce(this.resetIdleTimer, 300))
    window.addEventListener('keypress', debounce(this.resetIdleTimer, 300))
    window.addEventListener('scroll', debounce(this.resetIdleTimer, 300), true)

    window.addEventListener('visibilitychange', this.handleVisibilityChange)
    window.addEventListener('focus', this.handleFocus)
    window.addEventListener('blur', this.handleBlur)

    window.addEventListener('unload', this.unload)
  }

  startIdleTimer = () => {
    if (this.idleIntervalId) {
      clearInterval(this.idleIntervalId)
    }
    this.idleIntervalId = setInterval(this.idleTimerIncrement, ONE_MIN)
  }

  stopIdleTimer = () => {
    if (this.idleIntervalId) {
      clearInterval(this.idleIntervalId)
    }
  }

  resetIdleTimer = () => {
    if (!this.isSessionActive) return
    this.idleTime = 0
  }

  idleTimerIncrement = () => {
    if (!this.isSessionActive) return

    this.idleTime++
    this.periodCount++

    if (this.periodCount % PERIOD_INTERVAL === 0 && this.periodCount !== 0) {
      if (this.userSessionFrom) {
        this.sendMetricToServer(this.userSessionFrom, formatMetricDate())
      }
      this.userSessionFrom = formatMetricDate()
    }

    if (this.idleTime === MAX_SESSION_DURATION - 1) {
      NotificationSys.showWarningWithTimer('You will be logged out in {time}.', 'Stay logged in', 1)
    }

    if (this.idleTime === MAX_SESSION_DURATION) {
      this.stopUserSession()
      clearLocalStorage()
      NotificationSys.showWarning(SESSION_IS_EXPIRED)
      router.navigate(routes.signIn)
    }
  }

  startUserSession = () => {
    this.isSessionActive = true
    this.userSessionFrom = formatMetricDate()
    this.startIdleTimer()
  }

  stopUserSession = () => {
    this.isSessionActive = false
    this.stopIdleTimer()
    if (this.userSessionFrom) {
      this.sendMetricToServer(this.userSessionFrom, formatMetricDate())
    }
    this.userSessionFrom = null
    this.periodCount = 0
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.stopUserSession()
      return
    }

    this.startUserSession()
  }

  handleFocus = () => {
    this.startUserSession()
  }

  handleBlur = () => {
    this.stopUserSession()
  }

  sendMetricToServer = (startDate: string, endDate: string) => {
    const user = getFromLocalStorage(USER_DATA) as User | null
    if (user) {
      addTotalEngagementMetric({
        userId: user.id,
        engagedFrom: startDate,
        engagedTo: endDate,
      })
    }
  }

  unload = () => {
    this.stopUserSession()
  }
}

export const timeMeasureSystem = new Timer()
