import { Box, Stack, Tab, Tabs } from '@mui/material'
import { getOrganizationalUsers } from 'api/organization'
import { getOrganizationalSubUsers } from 'api/users'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { getTitle } from 'components/tabs/TabsTitle'
import { useCohortsFilter } from 'features/Cohorts'
import { useGridControl } from 'hooks/useGridControl'
import { useTabsControl } from 'hooks/useTabsControl'
import React, { useCallback, useMemo } from 'react'
import { BaseTableRequest, FilterModel, UserTypeTab } from 'types'
import { useGetDataSource } from 'utils'
import { useOrgRoleFilter } from '../../hooks/useOrgRoleFilter'
import { useOrgStatusFilter } from '../../hooks/useOrgStatusFilter'
import { useUserOrgColumns } from '../../hooks/useUserOrgColumns'
import { useUserOrgTraineeColumns } from '../../hooks/useUserOrgTraineeColumns'

export function UsersOrganizationPage() {
  const { cohortsFilterValue, CohortsFilter } = useCohortsFilter()
  const { selectedTab, handleChangeSelectedTab } = useTabsControl<UserTypeTab>(UserTypeTab.TRAINEE)
  const { RoleFilter, rolesFilterValue } = useOrgRoleFilter()
  const { StatusFilter, statusFilterValue } = useOrgStatusFilter()

  const apiMethod = useMemo(() => {
    if (selectedTab === UserTypeTab.TRAINEE) {
      return getOrganizationalSubUsers
    }

    return getOrganizationalUsers
  }, [selectedTab])

  const getOrgUsersWrapper = useCallback(
    (params: BaseTableRequest) => {
      let extendFilter: FilterModel = params.filter || {}

      if (cohortsFilterValue) {
        extendFilter.cohortId = cohortsFilterValue
      }

      if (rolesFilterValue) {
        extendFilter.type = rolesFilterValue
      }

      if (statusFilterValue) {
        extendFilter = {
          ...extendFilter,
          ...statusFilterValue,
        }
      }

      return apiMethod({
        ...params,
        filter: extendFilter,
      })
    },
    [apiMethod, cohortsFilterValue, rolesFilterValue, statusFilterValue],
  )

  const getDataSource = useGetDataSource(getOrgUsersWrapper)
  const { onGridReady } = useGridControl(getDataSource)
  const traineeColumnDefs = useUserOrgTraineeColumns()
  const orgUsersColumnDefs = useUserOrgColumns()

  const columns = useMemo(() => {
    if (selectedTab === UserTypeTab.TRAINEE) {
      return traineeColumnDefs
    }

    return orgUsersColumnDefs
  }, [orgUsersColumnDefs, selectedTab, traineeColumnDefs])

  const left = (
    <>
      <Tabs value={selectedTab} onChange={handleChangeSelectedTab} indicatorColor="primary">
        <Tab value={UserTypeTab.TRAINEE} label={getTitle('Trainees')} />
        <Tab value={UserTypeTab.ORG} label={getTitle('Organizational Users')} />
      </Tabs>
    </>
  )

  return (
    <>
      <TableToolbar left={left} />

      {selectedTab === UserTypeTab.TRAINEE && (
        <Stack flexDirection="row" mt={1} mb={2} className="gap-16">
          <Box minWidth="200px">{CohortsFilter}</Box>
        </Stack>
      )}

      {selectedTab === UserTypeTab.ORG && (
        <>
          <Stack flexDirection="row" alignItems="center" mt={1} mb={2} className="gap-16">
            <Box minWidth="280px">{RoleFilter}</Box>
            <Box minWidth="280px">{StatusFilter}</Box>
          </Stack>
        </>
      )}

      <InfinityAgGrid pagination columnDefs={columns} onGridReady={onGridReady} />
    </>
  )
}
