import { UserType } from 'types'

export function checkRole(userRoles: UserType[], allowedRoles: UserType[]) {
  return userRoles.some((type) => allowedRoles.includes(type))
}

export function isRoleAdmin(userRoles: UserType[]) {
  const allowedRoles = [UserType.ADMIN]
  return checkRole(userRoles, allowedRoles)
}

export function isRoleClient(userRoles: UserType[]) {
  const allowedRoles = [UserType.TRAINEE, UserType.CAREGIVER, UserType.CARETEAM]
  return checkRole(userRoles, allowedRoles)
}

export function isRoleOrganization(userRoles: UserType[]) {
  const allowedRoles = [
    UserType.ORG_OWNER,
    UserType.ORG_SUPERVISOR,
    UserType.ORG_FACILITY_MANAGER,
    UserType.ORG_REGIONAL_MANAGER,
  ]
  return checkRole(userRoles, allowedRoles)
}
