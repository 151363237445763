import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { Course, TableResponse, Tip } from 'types'

export function useTipsMetadata(tips?: TableResponse<Tip>): LabelValue[] {
  return useMemo(() => {
    if (!tips) {
      return []
    }

    return [
      {
        label: 'Tips Total',
        value: tips.data.count,
      },
      {
        label: 'Tips Published',
        value: tips.data.publishedCount || 0,
      },
    ]
  }, [tips])
}
