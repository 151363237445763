import {
  ApiSuccessResponse,
  BaseTableRequest,
  Pathway,
  PathwayRequestAddCourse,
  PathwayRequestAddPoints,
  PathwayRequestAddQuery,
  PathwayRequestAddTimelinePost,
  PathwayRequestCheckUnique,
  PathwayRequestCreate,
  PathwayRequestDeletePoint,
  PathwayRequestDeletePoints,
  PathwayRequestOrderPoints,
  PathwayRequestUpdate,
  PathwayTypeRequestUpdate,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getPathways = (request?: BaseTableRequest): Promise<TableResponse<Pathway>> => {
  return axiosInstance.post<BaseTableRequest, TableResponse<Pathway>>(
    '/training-pathways',
    request || {},
  )
}

export const getPathway = (request: RequestById) => {
  return axiosInstance.post<RequestById, Response<Pathway>>('/training-pathways/read', request)
}

export const updatePathway = (request: PathwayRequestUpdate) => {
  return axiosInstance.post<RequestById, Response<Pathway>>('/training-pathways/update', request)
}

export const updatePathwayType = (request: PathwayTypeRequestUpdate) => {
  return axiosInstance.post<RequestById, Response<void>>('/training-pathway-types/update', request)
}

export const pathwaysAddCourse = (request: PathwayRequestAddCourse) => {
  return axiosInstance.post<BaseTableRequest, Response>('/training-pathways/add-course', request)
}

export const pathwaysAddQuery = (request: PathwayRequestAddQuery) => {
  return axiosInstance.post<Request, Response>('/training-pathways/add-query', request)
}

export const pathwaysAddPoints = (request: PathwayRequestAddPoints) => {
  return axiosInstance.post<Request, Response>('/training-pathways/add-points', request)
}

export const pathwaysAddTimelinePost = (request: PathwayRequestAddTimelinePost) => {
  return axiosInstance.post<BaseTableRequest, Response>(
    '/training-pathways/add-care-article',
    request,
  )
}

export const pathwaysDeletePoint = (request: PathwayRequestDeletePoint) => {
  return axiosInstance.post<Request, Response>('/training-pathways/delete-point', request)
}

export const pathwaysDeletePoints = (request: PathwayRequestDeletePoints) => {
  return axiosInstance.post<Request, Response>('/training-pathways/delete-points', request)
}

export const pathwaysOrderPoints = (request: PathwayRequestOrderPoints) => {
  return axiosInstance.post<Request, Response>('/training-pathways/order-points', request)
}

export const createPathway = (request: PathwayRequestCreate) => {
  return axiosInstance.post<PathwayRequestCreate, Response<Pathway>>(
    '/training-pathways/create',
    request,
  )
}

export const checkPathwayNameUniqueness = (request: PathwayRequestCheckUnique) => {
  return axiosInstance.post<PathwayRequestCheckUnique, Response<ApiSuccessResponse>>(
    '/training-pathways/check-name',
    request,
  )
}

export const checkPathwayTypeNameUniqueness = (request: PathwayRequestCheckUnique) => {
  return axiosInstance.post<PathwayRequestCheckUnique, Response<ApiSuccessResponse>>(
    '/training-pathway-types/check-name',
    request,
  )
}
