import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

interface Props {
  handleLogout: () => void
}

const AdminProfileInfo = (props: Props) => {
  const { handleLogout } = props
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
      <List
        component="nav"
        sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
      >
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Box>
  )
}

export default AdminProfileInfo
