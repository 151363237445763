import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Box, FormLabel, Grid, Stack, TextField } from '@mui/material'
import { updateUser } from 'api/users'
import Breadcrumbs from 'components/Breadcumbs'
import FormCheckbox from 'components/form-elements/FormCheckbox'
import FormInput from 'components/form-elements/FormInput'
import FormSelect from 'components/form-elements/FormSelect'
import ConfirmModal from 'components/modals/ConfirmModal'
import NotificationSys from 'components/NotificationSystem'
import { EMAIL_IS_INVALID, FIELD_IS_REQUIRED } from 'const'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { routes } from 'routes/routes'
import { AccessCodeDropdownItem, Pathway, TableResponse, UserModel, UserUpdateRequest } from 'types'
import { boolean, object, string, TypeOf } from 'zod'

const formSchema = object({
  firstname: string().min(1, FIELD_IS_REQUIRED),
  lastname: string().min(1, FIELD_IS_REQUIRED),
  email: string().email(EMAIL_IS_INVALID),
  accessCodeId: string(),
  shouldLeaveOrganization: boolean(),
})

type FormType = TypeOf<typeof formSchema>

export function UserOrganizationDetailsForm({
  user,
  pathways,
  accessCodes,
  onAfterUpdate,
}: {
  user: UserModel
  pathways: TableResponse<Pathway>
  accessCodes: AccessCodeDropdownItem[]
  onAfterUpdate: () => void
}) {
  const defaultValues = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    accessCodeId: user.isUnlicensed ? 'null' : String(user.accessCodeId),
    shouldLeaveOrganization: !!user.leftOrganizationAt,
  }

  const methods = useForm<FormType>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const saveUserMutation = useMutation(updateUser)

  const getRequestData = useCallback(() => {
    const values = methods.getValues()
    const accessCodeId =
      values.accessCodeId === 'null' ? user.accessCodeId : parseInt(values.accessCodeId)

    const data: UserUpdateRequest = {
      id: user.id,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      accessCodeId,
      shouldLeaveOrganization: values.shouldLeaveOrganization,
      isUnlicensed: values.accessCodeId === 'null',
    }

    return data
  }, [methods, user.id, user.accessCodeId])

  const handleUpdate = useCallback(async () => {
    setShowSaveConfirm(false)
    const request = getRequestData()
    await saveUserMutation.mutateAsync(request)
    NotificationSys.showSuccess('User updated')
    onAfterUpdate()
  }, [getRequestData, onAfterUpdate, saveUserMutation])

  const pathwayName = useMemo(() => {
    const pathway = pathways.data.rows.find(
      (item) => item.trainingPathwayTypeId === user.trainingPathwayTypeId,
    )
    return pathway ? pathway.name : ''
  }, [pathways, user])

  const accessCodeOptions = useMemo(() => {
    const options = accessCodes.map(({ id, code, specificState, specificUnitName }) => ({
      value: id,
      label: `${specificState}/${specificUnitName}/${code}`,
    }))

    if (
      user.accessCode &&
      !user.isUnlicensed &&
      !options.find((o) => o.value === user.accessCode?.id)
    ) {
      const { id, code, specificState, specificUnitName } = user.accessCode
      options.unshift({
        value: id,
        label: `${specificState}/${specificUnitName}/${code}`,
      })
    }

    return [
      {
        value: 'null',
        label: 'Unlicensed',
      },
      ...options,
    ]
  }, [accessCodes, user.isUnlicensed, user.accessCode])

  const path = useMemo(() => {
    return [
      { href: routes.orgUsers, text: 'Users' },
      {
        text: `${user.firstname} ${user.lastname}`,
      },
    ]
  }, [user.firstname, user.lastname])

  const [showSaveConfirm, setShowSaveConfirm] = useState(false)

  const confirmText = (
    <span>
      Please note that upon confirmation, the trainee will not have access to the trainee portal
      until a new access code is assigned. For assistance with new code activation, please contact
      support at{' '}
      <a href="mailto:contact@proactivememoryservices.com">contact@proactivememoryservices.com</a>.
      Be aware that this change will incur an additional licensing fee.
    </span>
  )

  const handleCloseConfirm = useCallback(() => {
    setShowSaveConfirm(false)
  }, [])

  const handleSubmit = useCallback(() => {
    const request = getRequestData()
    if (request.isUnlicensed && !user.isUnlicensed) {
      setShowSaveConfirm(true)
    } else {
      handleUpdate()
    }
  }, [getRequestData, handleUpdate, user.isUnlicensed])

  return (
    <>
      <Breadcrumbs path={path} />

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(handleSubmit)}
          noValidate
          width="100%"
          mt={4}
        >
          <Grid container spacing={4}>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Stack spacing={2}>
                <FormInput label="First Name" name="firstname" required fullWidth />
                <FormInput label="Last Name" name="lastname" required fullWidth />
                <FormInput label="Email" name="email" fullWidth disabled />
              </Stack>

              <Stack spacing={2} mt={2}>
                {pathwayName && (
                  <Stack spacing={0.5}>
                    <FormLabel>Pathway Type</FormLabel>
                    <TextField size="small" value={pathwayName} disabled />
                  </Stack>
                )}
                <FormSelect
                  label="State/Unit"
                  name="accessCodeId"
                  required={!user.leftOrganizationAt}
                  options={accessCodeOptions}
                  disabled={!!user.leftOrganizationAt}
                  maxRows={5}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                />
                <FormCheckbox
                  name="shouldLeaveOrganization"
                  label="Left the Organization"
                  confirmText="Please confirm that the trainee has left the organization."
                  disabled={!!user.leftOrganizationAt}
                />
              </Stack>
            </Grid>

            <Grid item sm={12} md={12} lg={12} xl={12}>
              <LoadingButton
                disabled={saveUserMutation.isLoading}
                loading={saveUserMutation.isLoading}
                variant="contained"
                type="submit"
                sx={{ width: '120px' }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>

        {showSaveConfirm && (
          <ConfirmModal
            title=""
            loading={false}
            body={confirmText}
            handleClose={handleCloseConfirm}
            handleConfirm={handleUpdate}
          />
        )}
      </FormProvider>
    </>
  )
}
