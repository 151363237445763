import { AxiosResponse } from 'axios'
import {
  ApiSuccessResponse,
  BaseTableRequest,
  Tip,
  TipRequestCreate,
  RequestById,
  Response,
  TableResponse,
  PartialNull,
} from 'types'
import axiosInstance from './axios'

export const getTips = async (request?: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<Tip>>('/tips', request)
}

export const createTip = async (request: TipRequestCreate): Promise<Response<Tip>> => {
  return axiosInstance.post('/tips/create', request)
}

export const checkTipCanPublish = async (request: { id: number }): Promise<void> => {
  return axiosInstance.post('/tips/can-publish', request)
}

export const updateTip = async (request: PartialNull<Tip>): Promise<Response<Tip>> => {
  return axiosInstance.post('/tips/update', request)
}

export const getTipById = async (request: RequestById): Promise<Response<Tip>> => {
  return axiosInstance.post('/tips/read', request)
}

export const deleteTip = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/tips/delete', request)
}

export const checkTipUniqueness = async (request: { name: string }): Promise<boolean> => {
  const result: AxiosResponse<ApiSuccessResponse> = await axiosInstance.post(
    '/tips/check-name',
    request,
  )

  return result.data.success
}

export const updateTipsOrder = (request: { ids: number[] }): Promise<ApiSuccessResponse> => {
  return axiosInstance.post('/tips/order', request)
}
