import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import columnRenderers from 'components/column-renderers'
import Loader from 'components/placeholders/ModalLoadingPlaceholder'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import { defaultModalStyle, minButtonWidth } from 'const'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { TableResponse } from 'types'
import { PathwayAddModalItem } from '../../models/Pathway.model'

const modalStyle = {
  ...defaultModalStyle,
  width: '650px',
}

export function PathwayAddItemModal({
  saving,
  handleClose,
  addedItems,
  getItems,
  title,
  handleAdd,
}: {
  handleClose: () => void
  addedItems: number[]
  getItems: () => Promise<TableResponse<PathwayAddModalItem>>
  title: string
  handleAdd: (items: PathwayAddModalItem[]) => void
  saving: boolean
}) {
  const { data, isLoading } = useQuery('getItems', () => getItems())
  const [selectedItems, setSelectedItems] = useState<PathwayAddModalItem[]>([])
  const items = useMemo(() => {
    if (data?.data) {
      return data?.data.rows.filter((r) => !addedItems.includes(r.id))
    }

    return undefined
  }, [addedItems, data])

  const columnDefs: ColDef<PathwayAddModalItem>[] = [
    {
      headerName: '',
      colId: 'checked',
      cellRenderer: columnRenderers.checkbox<PathwayAddModalItem>((data) => {
        if (selectedItems.indexOf(data) == -1) {
          setSelectedItems([...selectedItems, data])
        } else setSelectedItems(selectedItems.filter((s) => s != data))
      }),
      valueGetter: ({ data }) => {
        if (data?.id) {
          return selectedItems.includes(data)
        }

        return false
      },
      width: 40,
      minWidth: 0,
      cellStyle: { padding: 0 },
      resizable: false,
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      resizable: false,
    },
  ]

  const handleAddClicked = useCallback(() => {
    handleAdd(selectedItems)
  }, [handleAdd, selectedItems])

  return (
    <Box sx={modalStyle}>
      <Loader show={isLoading} />
      {items && (
        <>
          <Typography variant="h5" mb={3}>
            Add {title}
          </Typography>

          <Box component="form" width="100%" noValidate>
            <Stack spacing={1} sx={{ height: '500px' }}>
              <InfinityAgGrid
                rowModelType="clientSide"
                columnDefs={columnDefs}
                floatingFiltersHeight={0}
                rowData={items}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
              <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
                Close
              </Button>
              <LoadingButton
                disabled={!selectedItems.length}
                loading={saving}
                variant="contained"
                type="button"
                onClick={handleAddClicked}
                sx={minButtonWidth}
              >
                Add ({selectedItems.length})
              </LoadingButton>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  )
}
