import { Organization } from './organization.types'

export enum EAccessCodeType {
  institutionAccessCode = 'institutionAccessCode',
  promoCode = 'promoCode',
}

// Different models on backend for create and edit. Need to refactor after backend changes
export interface BaseAccessCode {
  codeType: EAccessCodeType
  reason: string
  organizationId?: number
  organizationName?: string
  organizationType?: string
  organizationTwoDigits?: string
  twoDigitsOrg?: string
  organizationSingleLetter?: string
  singleLetterOrg?: string
  effectiveDate: string
  expiryDate: string
  specificState: string
  state?: string
  specificUnitName: string
  specificTwoDigits: string
  twoDigitsSpecific?: string
  duration: string
  numberOfUsers: number
  id?: number
  ownerId?: number
  ownerFirstName?: string
  ownerLastName?: string
  ownerEmail?: string
  regionalManagerId?: number
  regionalManagerFirstName?: string
  regionalManagerLastName?: string
  regionalManagerEmail?: string
  facilityManagerId?: number
  facilityManagerFirstName?: string
  facilityManagerLastName?: string
  facilityManagerEmail?: string
  supervisorId?: number
  supervisorFirstName?: string
  supervisorLastName?: string
  supervisorEmail?: string
}

export interface AccessCode extends BaseAccessCode {
  code: string
  organization?: Organization
}

export interface AccessCodeDropdownItem {
  id: number
  code: string
  state: string
  unit: string
  specificUnitName: string
  specificState: string
}
