import {
  BaseTableRequest,
  Organization,
  OrganizationalUser,
  OrganizationUserTree,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import axiosInstance from './axios'

export const getOrganizations = async (request?: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<Organization>>(
    '/organizations',
    request,
  )
}

export const getOrganizationUserTree = async (
  request?: RequestById,
): Promise<Response<OrganizationUserTree>> => {
  return axiosInstance.post('/organizations/users/tree', request)
}

export const getOrganizationalUsers = async (
  request: BaseTableRequest,
): Promise<TableResponse<OrganizationalUser[]>> => {
  return axiosInstance.post('/users/organizational', request)
}
