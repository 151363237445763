import {
  MetricRequestCareArticleEngagement,
  MetricRequestLearnArticleEngagement,
  MetricRequestLessonEngagement,
  MetricRequestLessonViewCount,
  MetricRequestQueryEngagement,
  MetricRequestQuestionnaireEngagement,
  MetricRequestTotalEngagement,
  Nullish,
} from 'types'
import { TOKEN } from 'utils'
import { getBaseURL, httpHeaders } from './axios'

// Right now we have a problem for sending metrics after logout.
// Token is stored in localStorage, and we clear it after logout.
// And metrics get token from localStorage before these changes, so we get 401.
// Here we store token in memory, and we don't clear it after logout, so we can send metrics after logout.
// But because token is in memory and will be cleared after refresh
// and time of token is around 15min, it's not a big security issue.
class MetricsClient {
  private token: Nullish<string> = localStorage.getItem(TOKEN)

  setToken(token: string): void {
    this.token = token
  }

  getToken(): Nullish<string> {
    return this.token
  }

  clearToken(): void {
    this.token = null
  }
}

export const metricsClient = new MetricsClient()

const prepareHeaders = () => {
  const token = metricsClient.getToken()
  const headers: HeadersInit = {
    ...httpHeaders,
    authorization: `Bearer ${token}`,
  }
  return headers
}

export const addLessonViewMetric = async (request: MetricRequestLessonViewCount) => {
  fetch(getBaseURL() + '/metrics/lesson-view', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addLessonEngagementMetric = (request: MetricRequestLessonEngagement) => {
  return fetch(getBaseURL() + '/metrics/lesson-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addLearnArticleEngagementMetric = (request: MetricRequestLearnArticleEngagement) => {
  return fetch(getBaseURL() + '/metrics/learn-article-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}
export const addQueryEngagementMetric = (request: MetricRequestQueryEngagement) => {
  return fetch(getBaseURL() + '/metrics/query-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addCareArticleEngagementMetric = (request: MetricRequestCareArticleEngagement) => {
  return fetch(getBaseURL() + '/metrics/care-article-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addQuestionnaireEngagementMetric = (request: MetricRequestQuestionnaireEngagement) => {
  return fetch(getBaseURL() + '/metrics/questionnaire-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}

export const addTotalEngagementMetric = (request: MetricRequestTotalEngagement) => {
  fetch(getBaseURL() + '/metrics/total-engagement', {
    keepalive: true,
    method: 'POST',
    headers: prepareHeaders(),
    body: JSON.stringify(request),
  })
}
