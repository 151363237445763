import { Stack, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import {
  FilterModelText,
  Nullish,
  Option,
  UserOrganizationalRoles,
  UserType,
  UserTypeLabels,
} from 'types'

interface RoleOption {
  value: UserType
  label: string
}

export function useOrgRoleFilter() {
  const [rolesValue, setRolesValue] = useState<RoleOption[]>([])

  const rolesFilterValue: Nullish<FilterModelText> = useMemo(() => {
    if (rolesValue.length > 0) {
      return {
        type: 'text',
        op: 'overlap',
        value: rolesValue.map((role) => role.value),
      } as FilterModelText
    }

    return null
  }, [rolesValue])

  const options = useMemo(() => {
    return UserOrganizationalRoles.map((role) => ({
      value: role,
      label: UserTypeLabels[role],
    }))
  }, [])

  const handleChange = useCallback((_: SyntheticEvent, newValue: Option[]) => {
    setRolesValue(newValue as RoleOption[])
  }, [])

  const RoleFilter = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" className="gap-8">
        <Typography>Role</Typography>

        <Autocomplete
          multiple
          options={options}
          value={rolesValue}
          onChange={handleChange}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              name="role"
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              }}
            />
          )}
        />
      </Stack>
    )
  }, [handleChange, options, rolesValue])

  return { RoleFilter, rolesFilterValue }
}
