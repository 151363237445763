import { getOrganizations, getOrganizationUserTree } from 'api/organization'
import { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Option, OrganizationUserRole, OrganizationUserTree, Response, UserType } from 'types'

export enum ManagerType {
  owner = 'owner',
  regional = 'regional',
  facility = 'facility',
  supervisor = 'supervisor',
}

export const ManagerTypeMapOnUserType = {
  [ManagerType.owner]: UserType.ORG_OWNER,
  [ManagerType.regional]: UserType.ORG_REGIONAL_MANAGER,
  [ManagerType.facility]: UserType.ORG_FACILITY_MANAGER,
  [ManagerType.supervisor]: UserType.ORG_SUPERVISOR,
}

export const ManagerTypeOrder = [
  ManagerType.owner,
  ManagerType.regional,
  ManagerType.facility,
  ManagerType.supervisor,
]

export const ManagerViewDataByType = {
  [ManagerType.owner]: {
    label: 'Owner',
    backendField: 'owner',
  },
  [ManagerType.regional]: {
    label: 'Regional Manager',
    backendField: 'regionalManager',
  },
  [ManagerType.facility]: {
    label: 'Facility Manager',
    backendField: 'facilityManager',
  },
  [ManagerType.supervisor]: {
    label: 'Supervisor',
    backendField: 'supervisor',
  },
}

type OrgIds = {
  [key in ManagerType]: number
}

export function useOrganizationFormData() {
  const [organizationId, setOrganizationId] = useState<number>(0)

  const [managerIds, setManagerIds] = useState<OrgIds>({
    owner: 0,
    regional: 0,
    facility: 0,
    supervisor: 0,
  })

  const handleChangeManagerId = useCallback((id: number, field: ManagerType) => {
    setManagerIds((prev) => ({ ...prev, [field]: id }))
  }, [])

  const { data: organizations } = useQuery('organizations', () => getOrganizations(), {
    select: (data) => data.data,
  })

  const organizationsOptions: Option[] = useMemo(() => {
    const defaultOption = { label: 'Add new organization...', value: 0 }
    if (!organizations) return [defaultOption]

    const options = organizations.rows.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    return [defaultOption, ...options]
  }, [organizations])

  const { data: organizationTree } = useQuery<
    Response<OrganizationUserTree>,
    unknown,
    OrganizationUserTree
  >(['organizations', organizationId], () => getOrganizationUserTree({ id: organizationId }), {
    enabled: !!organizationId,
    select: (data) => data.data,
  })

  const findOrganizationById = useCallback(
    (id: number) => {
      return organizations?.rows.find((item) => item.id === id)
    },
    [organizations],
  )

  const findUserInTreeById = useCallback(
    (userId: number): OrganizationUserRole | null => {
      if (!organizationTree) return null

      const traverse = (nodes: OrganizationUserRole[]): OrganizationUserRole | null => {
        for (const node of nodes) {
          if (node.id === userId) {
            return node
          }
          const found = traverse(node.children)
          if (found) {
            return found
          }
        }
        return null
      }

      return traverse(organizationTree)
    },
    [organizationTree],
  )
  const getDefaultOption = useCallback((managerType: ManagerType, action: string) => {
    return {
      label: `${action} new ${ManagerViewDataByType[managerType].label}...`,
      value: 0,
    }
  }, [])

  const generateOptionsByType = useCallback(
    (managerType: ManagerType) => {
      const defaultOption = getDefaultOption(managerType, 'Add')
      const typeIndex = ManagerTypeOrder.indexOf(managerType)
      const typesBefore = ManagerTypeOrder.slice(0, typeIndex)

      // find the closest parent according to the order
      let parentId = 0
      typesBefore.forEach((parentType) => {
        if (managerIds[parentType]) {
          parentId = managerIds[parentType]
        }
      })

      // find user by parent id and his children
      let children = organizationTree || []
      if (managerType !== ManagerType.owner) {
        const user = findUserInTreeById(parentId)
        children = user?.children || []
      }

      // filter children by type
      const options = children
        .filter((item) => {
          return item.type.includes(ManagerTypeMapOnUserType[managerType])
        })
        .map((item) => ({
          label: `${item.firstname} ${item.lastname}`,
          value: item.id,
        }))

      if (managerType === ManagerType.owner && options.length > 0) {
        return [getDefaultOption(managerType, 'Select'), ...options]
      }

      return [defaultOption, ...options]
    },
    [findUserInTreeById, getDefaultOption, managerIds, organizationTree],
  )

  const managerOptions = useMemo(
    () => ({
      [ManagerType.owner]: generateOptionsByType(ManagerType.owner),
      [ManagerType.regional]: generateOptionsByType(ManagerType.regional),
      [ManagerType.facility]: generateOptionsByType(ManagerType.facility),
      [ManagerType.supervisor]: generateOptionsByType(ManagerType.supervisor),
    }),
    [generateOptionsByType],
  )

  return {
    findOrganizationById,
    findUserInTreeById,
    managerIds,
    onChangeManagerId: handleChangeManagerId,
    managerOptions,
    organizationId,
    setOrganizationId,
    organizationsOptions,
  }
}
