import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { UserUpdater } from 'types'

export const columnCreatedOrUpdatedBy = ({
  propName,
  label,
  colId,
  sortable = false,
}: {
  propName: string
  label: string
  colId?: string
  sortable?: boolean
}): ColDef => {
  return {
    colId,
    headerName: label,
    flex: 1,
    minWidth: 300,
    sortable: sortable,
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      const a = nodeA.data[propName]
      const aString = a ? `${a?.firstname} ${a?.lastname} / ${a?.email}` : ''
      const b = nodeB.data[propName]
      const bString = b ? `${b?.firstname} ${b?.lastname} / ${b?.email}` : ''
      if (isDescending) {
        if (aString === bString) return 0
        if (!bString) return -1
      }
      return bString.localeCompare(aString)
    },
    cellRenderer: (props: ICellRendererParams) => {
      if (!props.data) return PLACEHOLDER

      const updater: UserUpdater = props.data[propName]
      if (!updater) return PLACEHOLDER

      return <div>{`${updater.firstname} ${updater.lastname} / ${updater.email}`}</div>
    },
  }
}

export const columnCreatedBy = (sortable = false) => {
  return columnCreatedOrUpdatedBy({
    propName: 'creator',
    label: 'Created by',
    colId: 'createdBy',
    sortable,
  })
}

export const columnUpdatedBy = (sortable = false) => {
  return columnCreatedOrUpdatedBy({
    propName: 'updater',
    label: 'Updated By',
    colId: 'updatedBy',
    sortable,
  })
}

export const columnCreatedOrUpdatedByCSV = (propName: string, label: string) => {
  return {
    label,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: (data: any) => {
      if (!data) return PLACEHOLDER

      const updater: UserUpdater = data[propName]
      if (!updater) return PLACEHOLDER

      return `${updater.firstname} ${updater.lastname} / ${updater.email}`
    },
  }
}

export const csvColumnCreatedBy = () => {
  return columnCreatedOrUpdatedByCSV('creator', 'Created by')
}

export const csvColumnUpdatedBy = () => {
  return columnCreatedOrUpdatedByCSV('updater', 'Updated by')
}
