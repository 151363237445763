import { useQuery } from 'react-query'
import { Box, Stack } from '@mui/material'
import { UICollapseProvider } from 'features/UI'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import ScrollContainer from 'components/page/ScrollContainer'
import { heightOfToolbar } from 'const'
import { getTips } from 'api/tips'
import { TipOrgGroup } from '../TipOrgGroup/TipOrgGroup'
import TableToolbar from 'components/table/TableToolbar'

const paddingLeft = { xs: 2, sm: 2 }
const paddingRight = { xs: 1, sm: 2 }

export const TipsOrgTablePage = () => {
  const { data, isLoading, isError } = useQuery('getTips', () =>
    getTips({ filter: { isPublished: { type: 'boolean', op: 'equals', value: true } } }),
  )

  if (isLoading) {
    return (
      <Box sx={{ paddingY: 3 }}>
        <LoadingPlaceholder />
      </Box>
    )
  }

  if (isError) {
    return <FailPlaceholder error="Can't load tips" />
  }

  return (
    <>
      <TableToolbar title="Tips Overview" />
      <UICollapseProvider>
        <Stack paddingTop={1} paddingLeft={paddingLeft} paddingRight={paddingRight} spacing={1}>
          <ScrollContainer deltaSpace={heightOfToolbar}>
            {data?.data.rows.map((item) => (
              <TipOrgGroup key={item.id} tip={item} />
            ))}
          </ScrollContainer>
        </Stack>
      </UICollapseProvider>
    </>
  )
}
