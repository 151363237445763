import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { LayoutClientPageTitle } from 'features/Layout'
import { useCheckAuthorizationAndRedirect } from 'hooks/useCheckAuthorizationAndRedirect'
import { useShowControl } from 'hooks/useShowControl'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { UserType } from 'types'
import { LayoutFooter } from '../../../features/Layout/components/LayoutFooter/LayoutFooter'
import { LayoutHeader } from '../../../features/Layout/components/LayoutHeader/LayoutHeader'
import { LayoutMain } from '../../../features/Layout/components/LayoutMain/LayoutMain'
import ClientsDrawersProvider from '../../context/ClientsDrawersProvider'
import DrawerHeaderFiller from '../../drawer/common/DrawerHeaderFiller'
import { ClientDrawerContent } from '../../drawer/navigation/ClientDrawerContent'
import { NavigationDrawer } from '../../drawer/navigation/NavigationDrawer'
import QuickTipModal from '../../modals/QuickTipModal'
import { ClientsHeaderContent } from '../components/ClientsHeaderContent'
import FooterContent from '../FooterContent'

const ClientsPageLayout = () => {
  const theme = useTheme()
  const [isTipOpen, , setTipClose] = useShowControl(false) // TODO enable by default
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'))

  const redirect = useCheckAuthorizationAndRedirect([
    UserType.TRAINEE,
    UserType.CARETEAM,
    UserType.CAREGIVER,
  ])

  if (redirect) {
    return <>{redirect}</>
  }

  return (
    <ClientsDrawersProvider>
      <Box display="flex">
        <LayoutHeader>
          <ClientsHeaderContent />
        </LayoutHeader>

        <LayoutFooter>
          <FooterContent />
        </LayoutFooter>

        <NavigationDrawer>
          <ClientDrawerContent />
        </NavigationDrawer>

        <LayoutMain
          sx={{
            height: '100%',
            marginBottom: matchesXs ? '72px' : 0,
          }}
        >
          <DrawerHeaderFiller />
          {matchesXs && <LayoutClientPageTitle />}

          <Outlet />
        </LayoutMain>
        <QuickTipModal isOpen={isTipOpen} handleClose={setTipClose} />
      </Box>
    </ClientsDrawersProvider>
  )
}

export default ClientsPageLayout
