import { CourseCollapseItem } from 'app/imports/App.components'
import { useMemo } from 'react'
import { ProgressCourse } from 'types'
import { ProgressExamItem } from '../ProgressExamItem/ProgressExamItem'
import { ProgressLessonItem } from '../ProgressLessonItem/ProgressLessonItem'

export function ProgressCourseItem({ course }: { course: ProgressCourse }) {
  const lessonsNotDone = useMemo(() => {
    const items = course.lessons.filter((itemInCourse) => itemInCourse.statusInTimeline !== 'done')

    const statusOrder = {
      retake: 0,
      review: 1,
      new: 2,
      done: 3,
    }

    return items.sort((a, b) => {
      const statusA = a.statusInTimeline ?? 'done'
      const statusB = b.statusInTimeline ?? 'done'
      return statusOrder[statusA] - statusOrder[statusB]
    })
  }, [course])

  const lessonsDone = useMemo(() => {
    return course.lessons.filter((itemInCourse) => itemInCourse.statusInTimeline === 'done')
  }, [course])

  return (
    <CourseCollapseItem
      id={course.courseId}
      name={course.courseName}
      bannerUrl={course.courseBannerUrl}
      status={course.courseStatus}
      isForceExpanded={false}
    >
      {lessonsNotDone.map((lesson) => {
        return <ProgressLessonItem key={lesson.lessonId} lesson={lesson} />
      })}

      <ProgressExamItem exam={course.questionnaire} />

      {lessonsDone.map((lesson) => {
        return <ProgressLessonItem key={lesson.lessonId} lesson={lesson} />
      })}
    </CourseCollapseItem>
  )
}
