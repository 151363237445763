import Toolbar from '@mui/material/Toolbar'
import { HEADER_ZINDEX } from 'const'
import React, { ReactNode } from 'react'
import { LayoutBar } from '../LayoutBar/LayoutBar'

export function LayoutHeader({ children }: { children: ReactNode }) {
  return (
    <LayoutBar position="fixed" elevation={0} sx={{ zIndex: HEADER_ZINDEX }}>
      <Toolbar>{children}</Toolbar>
    </LayoutBar>
  )
}
