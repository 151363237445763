import { Box } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { getUserHistory } from 'api/users'
import columnRenderers from 'components/column-renderers'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { useGridControl } from 'hooks/useGridControl'
import { userCellValueRenderer, userFieldNameRenderer } from 'models/user.model'
import React, { useCallback, useEffect, useMemo } from 'react'
import { BaseTableRequest, Pathway, TableResponse } from 'types'
import { useGetDataSource } from 'utils'

export function UserHistory({
  userId,
  pathways,
  needUpdate,
  resetNeedUpdate,
}: {
  userId: number
  pathways: TableResponse<Pathway>
  needUpdate: boolean
  resetNeedUpdate: () => void
}) {
  const getUserHistoryCallback = useCallback(
    (params: BaseTableRequest) => {
      const sort = params.sort || { field: 'updatedAt', sort: 'desc' }
      return getUserHistory({
        ...params,
        filter: {
          ...(params.filter || {}),
          userId: {
            type: 'number',
            op: 'equals',
            value: userId,
          },
        },
        sort,
      })
    },
    [userId],
  )

  const getDataSource = useGetDataSource(getUserHistoryCallback)

  const { gridRef, onGridReady } = useGridControl(getDataSource)

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Action',
        field: 'updateAction',
        maxWidth: 120,
        cellRenderer: columnRenderers.capitalizeRenderer('updateAction'),
      },
      {
        headerName: 'Field',
        field: 'fieldName',
        cellRenderer: userFieldNameRenderer('fieldName'),
        minWidth: 140,
      },
      {
        headerName: 'Old Value',
        field: 'oldValue',
        cellRenderer: userCellValueRenderer('oldValue', pathways),
        minWidth: 300,
      },
      {
        headerName: 'New Value',
        field: 'newValue',
        cellRenderer: userCellValueRenderer('newValue', pathways),
        minWidth: 300,
      },
      { headerName: 'Pathway', field: 'trainingPathwayTypeTitle', minWidth: 300 },
      { headerName: 'Date', field: 'updatedAt', cellRenderer: columnRenderers.date('updatedAt') },
    ]
  }, [pathways])

  useEffect(() => {
    if (needUpdate) {
      gridRef.current?.api.purgeInfiniteCache()
      resetNeedUpdate()
    }
  }, [gridRef, needUpdate, resetNeedUpdate])

  return (
    <>
      <TableToolbar title="History" />
      <Box height="800px">
        <InfinityAgGrid
          pagination
          innerRef={gridRef}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </Box>
    </>
  )
}
