import { MenuItem } from '@mui/material'
import DropdownButton from 'components/buttons/DropdownButton'
import { useCallback, useState } from 'react'
import {
  AddTrainingPathwayPointType,
  CareArticleType,
  ETrainingPathwayPointType,
  PathwayCoursePoint,
  PathwayGroupedPoint,
  PathwayPoint,
  PathwayType,
} from 'types'
import { PathwayAddItemModalFactory } from '../PathwayAddItemModalFactory/PathwayAddItemModalFactory'

export function PathwayControlPointAdd({
  id,
  points,
  type,
  onAdd,
}: {
  id: number
  points: PathwayGroupedPoint[]
  type: PathwayType
  onAdd: () => void
}) {
  const [addModalItemType, setAddModalItemType] = useState<AddTrainingPathwayPointType>()

  const handleAddCourseClicked = useCallback(() => {
    setAddModalItemType(AddTrainingPathwayPointType.course)
  }, [])

  const handleAddQueryClicked = useCallback(() => {
    setAddModalItemType(AddTrainingPathwayPointType.query)
  }, [])

  const handleAddCarePostClicked = useCallback(() => {
    setAddModalItemType(AddTrainingPathwayPointType.careArticle)
  }, [])

  const handleAddTaskPostClicked = useCallback(() => {
    setAddModalItemType(AddTrainingPathwayPointType.taskArticle)
  }, [])

  const handleAddItemModalClose = useCallback(() => {
    setAddModalItemType(undefined)
  }, [])

  const handleAddItemModalAdd = useCallback(() => {
    setAddModalItemType(undefined)
    onAdd()
  }, [onAdd])

  const getExistingRecords = (type: AddTrainingPathwayPointType) => {
    switch (type) {
      case AddTrainingPathwayPointType.course:
        return (
          points
            .filter((point) => point.type === 'course')
            .map((point) => (point as PathwayCoursePoint).id) || []
        )

      case AddTrainingPathwayPointType.query:
        return (
          points
            .filter((point) => point.type === ETrainingPathwayPointType.query)
            .map((point) => (point as PathwayPoint).queryId) || []
        )

      case AddTrainingPathwayPointType.careArticle:
        return (
          points
            .filter(
              (point) =>
                point.type === ETrainingPathwayPointType.careArticle &&
                point.careArticle?.type === CareArticleType.Care,
            )
            .map((point) => (point as PathwayPoint).careArticleId) || []
        )

      case AddTrainingPathwayPointType.taskArticle:
        return (
          points
            .filter(
              (point) =>
                point.type === ETrainingPathwayPointType.careArticle &&
                point.careArticle?.type === CareArticleType.Task,
            )
            .map((point) => (point as PathwayPoint).careArticleId) || []
        )

      default:
        return []
    }
  }

  return (
    <>
      {addModalItemType && (
        <PathwayAddItemModalFactory
          itemType={addModalItemType}
          addedItems={getExistingRecords(addModalItemType)}
          onClose={handleAddItemModalClose}
          onAdd={handleAddItemModalAdd}
          pathwayId={id}
        />
      )}

      <DropdownButton buttonTitle="ADD" sx={{ minWidth: '80px', justifyContent: 'space-between' }}>
        {type != PathwayType.caregiver && (
          <MenuItem onClick={handleAddCourseClicked}>Course</MenuItem>
        )}
        <MenuItem onClick={handleAddQueryClicked}>Query</MenuItem>
        <MenuItem onClick={handleAddCarePostClicked}>Guide Post</MenuItem>
        <MenuItem onClick={handleAddTaskPostClicked}>Action Post</MenuItem>
      </DropdownButton>
    </>
  )
}
