import styled from '@emotion/styled'
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { ArticleAudioUserView } from 'features/Article/components/ArticleAudioUserView/ArticleAudioUserView'
import React, { useCallback } from 'react'
import { Question } from 'types'
import { IMetricScope, IMetricType } from 'types/metric.types'
import QuizStep from './quiz-step'

const StyledRadioGroup = styled(RadioGroup)`
  gap: 12px;
  margin-top: 8px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;

  & > .MuiRadio-root {
    padding: 1px 9px 0;
  }
`

interface Props {
  question: Question
  answer?: string
  onChange: (value: string) => void
  handleClose: () => void
  disabled?: boolean
  metricType?: IMetricType
  metricEntityId?: number
  onSaveAudioMetric?: (value: number, uuid: string, scope: IMetricScope) => void
}

const QuestionScreen = (props: Props) => {
  const {
    disabled,
    answer,
    onChange,
    onSaveAudioMetric,
    handleClose,
    question,
    metricType,
    metricEntityId,
  } = props

  const onChangeWrapper = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      onChange(value)
    },
    [onChange],
  )

  const handleSaveAudioMetric = useCallback(
    (value: number, uuid: string, scope: IMetricScope) => {
      onSaveAudioMetric?.(value, uuid, scope)
    },
    [onSaveAudioMetric],
  )

  return (
    <QuizStep title={question.name} handleClose={handleClose}>
      <Typography>{question.stem}</Typography>
      <Typography>{question.question}</Typography>
      <ArticleAudioUserView
        enMediaUrl={question.enQuestionMediaUrl as string}
        esMediaUrl={question.esQuestionMediaUrl as string}
        metricType={metricType}
        metricEntityId={metricEntityId}
        onSaveMetric={handleSaveAudioMetric}
      />
      <FormControl>
        <Typography color="textSecondary">{question.instructions}</Typography>
        <StyledRadioGroup value={answer} defaultValue="female" onChange={onChangeWrapper}>
          {question.answers.map((answer) => (
            <StyledFormControlLabel
              value={answer.text}
              control={<Radio disabled={disabled} />}
              label={answer.text}
              key={`${question.id}_${answer.text}`}
            />
          ))}
        </StyledRadioGroup>
      </FormControl>
    </QuizStep>
  )
}

export default QuestionScreen
