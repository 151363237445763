import Toolbar from '@mui/material/Toolbar'
import React, { ReactNode } from 'react'
import { LayoutBar } from '../LayoutBar/LayoutBar'

export function LayoutFooter({ children }: { children: ReactNode }) {
  return (
    <LayoutBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, top: 'auto', bottom: 0 }}
    >
      <Toolbar>{children}</Toolbar>
    </LayoutBar>
  )
}
