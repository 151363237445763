import { Box, Button } from '@mui/material'
import { ColDef, ICellRendererParams, RowDragEvent } from 'ag-grid-community'
import columnRenderers from 'components/column-renderers'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import NotificationSys from 'components/NotificationSystem'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { Metadata } from 'features/Metadata'
import { columnCreatedBy, columnUpdatedBy, getActionColumn } from 'features/UI'
import { useExportCSV } from 'hooks/useExportCSV'
import { useGridControl } from 'hooks/useGridControl'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, FilterModel, TableResponse, Tip } from 'types'
import { useGetDataSource, withIdParam } from 'utils'
import { useTipsMetadata } from 'features/Tips/hooks/useTipsMetadata'
import { useTipsCSVField } from 'features/Tips/hooks/useTipsCSVField'
import {
  checkTipCanPublish,
  checkTipUniqueness,
  createTip,
  deleteTip,
  getTipById,
  getTips,
  updateTip,
  updateTipsOrder,
} from 'api/tips'
import ModalWithTextAndIcon, {
  NameAndIconRequest,
} from 'components/modals/simple-modals/ModalWithTextAndIcon'
import { useChangeEntity } from 'hooks/useChangeEntity'
import { useMutation } from 'react-query'

export const TipsTablePage = () => {
  const [tips, setTips] = useState<TableResponse<Tip>>()

  const getRequest = useCallback((req: BaseTableRequest) => {
    const filter: FilterModel = {
      ...(req.filter || {}),
    }

    const request = { ...req, filter }

    return getTips(request).then((res) => {
      setTips(res)
      return res
    })
  }, [])

  const getDataSource = useGetDataSource(getRequest)
  const { onGridReady, gridApi, columnApi } = useGridControl(getDataSource)
  const navigate = useNavigate()

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteTip,
      successCallback: () => gridApi?.purgeInfiniteCache(),
      warning: "Can't remove tip",
    })

  const onClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.tipEdit, id))
    },
    [navigate],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const [loading, setLoading] = useState(false)
  const [onSubmit, openAddModal, isOpenAddModal, closeAddModal] = useChangeEntity({
    setLoading,
    onSuccess: (response) => {
      navigate(withIdParam(routes.tipEdit, response.id))
    },
    requestFunc: createTip,
    existedNameWarning: "Name must be unique. Can't create the tip with existing name",
    warning: "Can't create the tip",
  })

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl({
    successCallback: (id: number, action: PublishAction) => {
      const isPublished = action === PublishAction.Publish
      if (isPublished) {
        NotificationSys.showSuccess(`Tip is published`)
      } else {
        NotificationSys.showSuccess(`Tip is unpublished`)
      }
      gridApi?.purgeInfiniteCache()
    },
    loadFunc: getTipById,
    updateFunc: updateTip,
  })

  const onPublish = useCallback(
    async (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      try {
        const isPublished = props?.data?.isPublished
        const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
        if (!isPublished) {
          await checkTipCanPublish({ id })
        }
        openPublishModal(id, action)
      } catch (e) {
        console.error(e)
      }
    },
    [openPublishModal],
  )

  const handleCreate = useCallback(
    async (values: NameAndIconRequest) => {
      const success = await checkTipUniqueness({ name: values.name })
      if (!success) {
        NotificationSys.showWarning('Tip with such name already exists')
        return setLoading(false)
      }
      if (!values.icon) {
        NotificationSys.showWarning('Cannot publish a tip without banner image')
        return setLoading(false)
      }

      onSubmit({ name: values.name, bannerMediaFileId: values.icon })
    },
    [onSubmit],
  )

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onClickEdit,
      },
      { name: 'Delete', onClick: openDeleteModal },
      { renderName: publishActionRender, onClick: onPublish },
    ]
  }, [onClickEdit, onPublish, openDeleteModal, publishActionRender])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Tip Name',
        cellRenderer: columnRenderers.createLink('name', routes.tipEdit),
        filter: 'agTextColumnFilter',
        colId: 'name',
        rowDrag: true,
      },
      {
        headerName: 'Published',
        cellRenderer: columnRenderers.published,
        minWidth: 160,
        colId: 'isPublished',
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        colId: 'createdAt',
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        colId: 'updatedAt',
        sort: 'desc',
      },
      columnUpdatedBy(),
      { ...getActionColumn(actions) },
    ]
  }, [actions])

  const updateTipsOrderMutation = useMutation(updateTipsOrder)

  const handleRowDragEnd = useCallback(
    (e: RowDragEvent<Tip>) => {
      const orderedIds = e.api
        .getRenderedNodes()
        .map((node) => (node.data ? node.data.id : null))
        .filter((id): id is number => id !== null)

      updateTipsOrderMutation.mutate(
        { ids: orderedIds },
        {
          onSuccess: () => {
            if (tips?.data.rows) {
              const orderedItems = [...tips?.data.rows].sort(
                (a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id),
              )
              setTips({
                ...tips,
                data: {
                  ...tips.data,
                  rows: orderedItems,
                },
              })
            }
          },
          onError: () => setTips(tips),
        },
      )
    },
    [tips, updateTipsOrderMutation],
  )

  const metadata = useTipsMetadata(tips)

  const csvFields = useTipsCSVField()

  const { csvLoading, onExportCSV } = useExportCSV(
    getRequest,
    gridApi,
    columnApi,
    csvFields,
    `Tip_Overview_${moment().format('YYYY-MM-DD')}.csv`,
  )

  return (
    <>
      <TableToolbar
        title="Tips Overview"
        exportCSVEnable
        csvLoading={csvLoading}
        onExportCSV={onExportCSV}
      >
        <Button variant="outlined" sx={{ minWidth: 120 }} onClick={openAddModal}>
          Add Tip
        </Button>
      </TableToolbar>

      <Box mb={2}>
        <Metadata data={metadata} />
      </Box>

      <InfinityAgGrid
        columnDefs={columnDefs}
        animateRows
        rowDragManaged
        rowDragEntireRow
        onGridReady={onGridReady}
        onRowDragEnd={handleRowDragEnd}
        pagination
      />

      <ConfirmRemoveModal
        entityToRemove="Tip"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Tip"
      />

      <ModalWithTextAndIcon
        title="Create Tip"
        label="Tip name"
        buttonText="Create"
        isOpen={isOpenAddModal}
        handleClose={closeAddModal}
        loading={loading}
        setLoading={setLoading}
        onSubmit={handleCreate}
      />
    </>
  )
}
