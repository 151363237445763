import React from 'react'
import {
  customNoRowsOverlay,
  defaultColDef,
  gridComponents,
  loadingOverlayComponent,
  ROWS_PER_PAGE,
} from '../../hooks/useGridControl'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

type Props<TData> = {
  innerRef?: React.RefObject<AgGridReact>
} & AgGridReactProps<TData>

function InfinityAgGrid<TData>(props: Props<TData>) {
  const { innerRef } = props
  return (
    <AgGridReact<TData>
      ref={innerRef}
      defaultColDef={defaultColDef}
      rowModelType="infinite"
      paginationPageSize={ROWS_PER_PAGE}
      cacheBlockSize={ROWS_PER_PAGE}
      cacheOverflowSize={ROWS_PER_PAGE}
      loadingOverlayComponent={loadingOverlayComponent}
      components={gridComponents}
      noRowsOverlayComponent={customNoRowsOverlay}
      rowHeight={48}
      suppressRowVirtualisation={true}
      {...props}
    />
  )
}

export default InfinityAgGrid
