import { ICellRendererParams } from 'ag-grid-community'
import capitalize from 'lodash/capitalize'
import {
  EUserEducation,
  EUserExperience,
  EUserHighestDegree,
  EUserLanguage,
  EUserRacialDesignation,
  Pathway,
  TableResponse,
  UserHistoryRow,
} from 'types'
import { PLACEHOLDER } from '../components/column-renderers'
import { getPathwayNameById } from './pathways.model'

export const yearsOfExperience = [
  {
    label: '0-5',
    value: EUserExperience.lessThan5Years,
  },
  {
    label: '5+',
    value: EUserExperience.equalOrGreaterThan5Years,
  },
]

export const educationOptions = [
  {
    label: 'High school or less',
    value: EUserEducation.highSchoolOrLess,
  },
  {
    label: 'Beyond high school',
    value: EUserEducation.beyondHighSchool,
  },
]

export const preferredLanguageOptions = [
  {
    label: 'English',
    value: EUserLanguage.english,
  },
  {
    label: 'Spanish',
    value: EUserLanguage.spanish,
  },
  {
    label: 'Chinese',
    value: EUserLanguage.chinese,
  },
  {
    label: 'Filipino',
    value: EUserLanguage.filipino,
  },
  {
    label: 'Other',
    value: EUserLanguage.other,
  },
]

export const yesNoOptions = [
  {
    label: 'No',
    value: 0,
  },
  {
    label: 'Yes',
    value: 1,
  },
]

export const careLevels = [
  {
    label: 'Very little, they are independent',
    value: 'veryLittle',
  },
  {
    label: 'Needs help doing a few things',
    value: 'fewThings',
  },
  {
    label: 'Needs help with most things',
    value: 'mostThings',
  },
  {
    label: 'Needs help with all',
    value: 'withAll',
  },
]

export const highestDegreeOptions = [
  {
    label: 'Prefer not to say',
    tableValue: 'Prefer not to say',
    value: EUserHighestDegree.preferNotToSay,
  },
  {
    label: 'Some High School',
    tableValue: 'Some High School',
    value: EUserHighestDegree.someHighSchool,
  },
  {
    label: 'High School Diploma',
    tableValue: 'High School Diploma',
    value: EUserHighestDegree.highSchoolDiploma,
  },
  {
    label: 'Associate Degree',
    tableValue: 'Associate Degree',
    value: EUserHighestDegree.associateDegree,
  },
  {
    label: 'Bachelor Degree',
    tableValue: 'Bachelor Degree',
    value: EUserHighestDegree.bachelorDegree,
  },
  {
    label: 'Master Degree',
    tableValue: 'Master Degree',
    value: EUserHighestDegree.masterDegree,
  },
  {
    label: 'PhD or MD',
    tableValue: 'PhD or MD',
    value: EUserHighestDegree.phDOrMD,
  },
  {
    label: 'Other advanced degrees (please specify)',
    tableValue: 'Other',
    value: EUserHighestDegree.other,
  },
]

export const racialDesignationOptions = [
  {
    label: 'Prefer not to say',
    tableValue: 'Prefer not to say',
    value: EUserRacialDesignation.preferNotToSay,
  },
  {
    label: 'African American or Black',
    tableValue: 'African American or Black',
    value: EUserRacialDesignation.africanAmericanOrBlack,
  },
  {
    label: 'Arab American or Middle Eastern',
    tableValue: 'Arab American or Middle Eastern',
    value: EUserRacialDesignation.arabAmericanOrMiddleEastern,
  },
  {
    label: 'Asian American or South Asian',
    tableValue: 'Asian American or South Asian',
    value: EUserRacialDesignation.asianAmericanOrSouthAsian,
  },
  {
    label: 'Hispanic or Latino',
    tableValue: 'Hispanic or Latino',
    value: EUserRacialDesignation.hispanicOrLatino,
  },
  {
    label: 'Native American or Alaska Native',
    tableValue: 'Native American or Alaska Native',
    value: EUserRacialDesignation.nativeAmericanOrAlaskaNative,
  },
  {
    label: 'Native Hawaiian or Pacific Islander',
    tableValue: 'Native Hawaiian or Pacific Islander',
    value: EUserRacialDesignation.nativeHawaiianOrPacificIslander,
  },
  {
    label: 'White',
    tableValue: 'White',
    value: EUserRacialDesignation.white,
  },
  {
    label: 'Other (please specify)',
    tableValue: 'Other',
    value: EUserRacialDesignation.other,
  },
]

export const userCellValueRenderer =
  (field: keyof UserHistoryRow, pathways: TableResponse<Pathway>) =>
  (cell: ICellRendererParams<UserHistoryRow>) => {
    const { data } = cell
    if (!data) {
      return PLACEHOLDER
    }
    const value = data[field]

    if (data.fieldName === 'experience') {
      const label = yearsOfExperience.find((item) => item.value === value)?.label
      return label || PLACEHOLDER
    }

    if (data.fieldName === 'education') {
      const label = educationOptions.find((item) => item.value === value)?.label
      return label || PLACEHOLDER
    }

    if (data.fieldName === 'language') {
      return capitalize(value as string)
    }

    if (data.fieldName === 'cohorts') {
      if (value === '') {
        return PLACEHOLDER
      }

      return (value as string).split(',').join(', ')
    }

    if (data.fieldName === 'trainingPathwayTypeId') {
      return getPathwayNameById(pathways, Number(value))
    }

    return value
  }

export const userFieldNameRenderer =
  (field: keyof UserHistoryRow) => (cell: ICellRendererParams<UserHistoryRow>) => {
    const { data } = cell
    if (!data) {
      return PLACEHOLDER
    }
    const value = data[field]

    if (data.fieldName === 'trainingPathwayTypeId') {
      return 'Pathway Type'
    }

    return capitalize(value as string)
  }
