export enum BackendGender {
  male = 'male',
  female = 'female',
  selfDescribe = 'preferToSelfDescribe',
  notAnswer = 'preferNotToAnswer',
}

export type Profile = {
  firstName: string
  lastName: string
  street?: string
  zipCode?: string
  state?: string
  country?: string
  city?: string
  phoneNumber?: string
  dateOfBirth: string | null
  gender: BackendGender
  email: string
  role?: string
}

export type ProfileUpdateRequest = Profile

export type TrainingDetails = {
  code: string
  effectiveDate: string
  email: string
  expireDate: string
  education: string
  experience: string
  language: string
  other: string
}
