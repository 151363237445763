import { AccessManagement } from 'features/AccessManagement'
import {
  AuthPageForgotPassword,
  AuthPageResetPassword,
  AuthPageSignIn,
  AuthPageSignUp,
  AuthPageSignupFamily,
  AuthPageSignUpTeamMember,
  AuthPageSignupTrainee,
  AuthPagesLayout,
  AuthPageSuccessSubscription,
} from 'features/Auth'
import { CareArticlePageEdit, CareArticlesPage } from 'features/CareArticle'
import { CohortsPage } from 'features/Cohorts'
import { CourseEditPage, CoursesTablePage } from 'features/Course'
import { ExamPageEdit } from 'features/Exam'
import {
  LearnLibraryAdminPage,
  LearnLibraryArticlePage,
  LearnLibraryTopicPage,
} from 'features/LearnLibrary'
import { LessonEditPage } from 'features/Lesson'
import { PathwayPageEdit, PathwayPageList } from 'features/Pathway'
import { ProgressPage } from 'features/Progress'
import { QueryPageEdit, QueryTablePage } from 'features/Query'
import { QuestionPageAdd, QuestionPageEdit, QuestionTablePage } from 'features/Questions'
import {
  ReportsEngagement,
  ReportsEngagementUser,
  ReportsEngagementUserSessionPage,
  ReportsQuestionnaires,
  ReportsQuizzes,
  ReportsViewCountLessons,
} from 'features/Reports'
import { ReportOrganizationCompletion } from 'features/Reports/components/ReportOrganizationCompletion/ReportOrganizationCompletion'
import { TagsPage } from 'features/Tags'
import { TipEditPage, TipsOrgTablePage, TipsTablePage } from 'features/Tips'
import { TimelineJourneyPage } from 'features/Timeline'
import { UserDetailsPage, UsersOrganizationPage, UsersPage } from 'features/User'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import AdminPageLayout from '../components/page/layouts/AdminPageLayout'
import ClientsPageLayout from '../components/page/layouts/ClientsPageLayout'
import { ArticleEditorPage } from '../features/Article/components/ArticleEditorPage/ArticleEditorPage'
import { LayoutOrganizationPage } from '../features/Layout/components/LayoutOrganizationPage/LayoutOrganizationPage'
import { OrganizationAccessManagement } from '../features/Organization/components/OrganizationAccessManagement/OrganizationAccessManagement'
import LearnLibrary from './dashboard/learn-library/LearnLibrary'
import { routes } from './routes'
import { UserOrganizationDetailsPage } from 'features/User/components/UserOrganizationDetails/UserOrganizationDetailsPage'

const authRoutes = [
  {
    path: '/',
    element: <AuthPagesLayout />,
    children: [
      {
        path: routes.signIn,
        element: <AuthPageSignIn />,
      },
      {
        path: routes.signUp,
        element: <AuthPageSignUp />,
      },
      {
        path: routes.signUpFamily,
        element: <AuthPageSignupFamily />,
      },
      {
        path: routes.signUpTrainee,
        element: <AuthPageSignupTrainee />,
      },
      {
        path: routes.signUpTeamMember,
        element: <AuthPageSignUpTeamMember />,
      },
      {
        path: routes.forgotPassword,
        element: <AuthPageForgotPassword />,
      },
      {
        path: routes.resetPassword,
        element: <AuthPageResetPassword />,
      },
      {
        path: routes.successSubscription,
        element: <AuthPageSuccessSubscription />,
      },
    ],
  },
]

const dashboardRoutes = [
  {
    path: '/',
    element: <ClientsPageLayout />,
    children: [
      { path: routes.journey, element: <TimelineJourneyPage /> },
      { path: routes.journeyItem, element: <TimelineJourneyPage /> },
      { path: routes.learnLibrary, element: <LearnLibrary /> },
      { path: routes.learnLibraryItem, element: <LearnLibrary /> },
      { path: routes.progress, element: <ProgressPage /> },
      // { path: routes.actionPlan, element: <ActionPlanPage /> },
    ],
  },
]

const adminRoutes = [
  {
    path: '/admin',
    element: <AdminPageLayout />,
    children: [
      { path: routes.users, element: <UsersPage /> },
      { path: routes.userDetails, element: <UserDetailsPage /> },

      { path: routes.accessManagement, element: <AccessManagement /> },

      /* Reports */
      { path: routes.reports_lessons, element: <ReportsViewCountLessons /> },
      { path: routes.reports_engagement, element: <ReportsEngagement /> },
      { path: routes.reports_engagement_user, element: <ReportsEngagementUser /> },
      {
        path: routes.reports_engagement_user_session,
        element: <ReportsEngagementUserSessionPage />,
      },
      { path: routes.reports_quizzes, element: <ReportsQuizzes /> },
      { path: routes.reports_questionnaires, element: <ReportsQuestionnaires /> },

      /* Content Management */
      { path: routes.courses, element: <CoursesTablePage /> },
      { path: routes.courseEdit, element: <CourseEditPage /> },
      { path: routes.lessonEdit, element: <LessonEditPage /> },
      { path: routes.manageLearnLibrary, element: <LearnLibraryAdminPage /> },
      { path: routes.manageTags, element: <TagsPage /> },
      { path: routes.manageCohorts, element: <CohortsPage /> },
      { path: routes.topicEdit, element: <LearnLibraryTopicPage /> },
      { path: routes.learnArticleEdit, element: <LearnLibraryArticlePage /> },
      { path: routes.manageTips, element: <TipsTablePage /> },
      { path: routes.tipEdit, element: <TipEditPage /> },

      { path: routes.manageCarePosts, element: <CareArticlesPage /> },
      { path: routes.timelinePostEdit, element: <CareArticlePageEdit /> },
      { path: routes.lessonContentEdit, element: <ArticleEditorPage /> },
      { path: routes.questionnaireEdit, element: <ExamPageEdit /> },
      { path: routes.quizEdit, element: <ExamPageEdit /> },
      { path: routes.queryEdit, element: <QueryPageEdit /> },
      { path: routes.questions, element: <QuestionTablePage /> },
      { path: routes.questionEdit, element: <QuestionPageEdit /> },
      { path: routes.questionAdd, element: <QuestionPageAdd /> },
      { path: routes.managePathways, element: <PathwayPageList /> },
      { path: routes.pathwayEdit, element: <PathwayPageEdit /> },
      { path: routes.manageQuery, element: <QueryTablePage /> },
    ],
  },
]

const organizationRoutes = [
  {
    path: '/organization',
    element: <LayoutOrganizationPage />,
    children: [
      { path: routes.orgUsers, element: <UsersOrganizationPage /> },
      { path: routes.orgUserDetails, element: <UserOrganizationDetailsPage /> },
      { path: routes.orgAccessManagement, element: <OrganizationAccessManagement /> },
      {
        path: routes.orgReports,
        element: <ReportOrganizationCompletion />,
      },
      { path: routes.orgTips, element: <TipsOrgTablePage /> },
    ],
  },
]

export const router = createBrowserRouter([
  ...authRoutes,
  ...dashboardRoutes,
  ...adminRoutes,
  ...organizationRoutes,
  {
    path: '*',
    element: <Navigate to={routes.signIn} replace />,
  },
])
