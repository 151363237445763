import { Pathway, TableResponse } from '../types'

export const pathwayCacheTime = 15 * 60 * 1000

// These pathways are hardcoded in the backend on all environments
export enum OldPathwayTypes {
  pathway1A = 1,
  pathway1B = 2,
  pathway2A = 3,
  pathway2B = 4,
  familyCaregiver = 5,
}

export function isNotEnglishPathway(pathwayTypeId: number): boolean {
  return pathwayTypeId === OldPathwayTypes.pathway1A || pathwayTypeId === OldPathwayTypes.pathway1B
}

export function isFamilyCaregiverPathway(pathwayTypeId: number): boolean {
  return pathwayTypeId === OldPathwayTypes.familyCaregiver
}

export function getPathwayNameById(pathways: TableResponse<Pathway>, pathwayTypeId?: number) {
  if (!pathwayTypeId) return ''

  const pathway = pathways.data.rows.find((item) => item.trainingPathwayTypeId === pathwayTypeId)

  return pathway ? pathway.name : ''
}
