import { styled } from '@mui/material/styles'

export const LayoutMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  minHeight: '100vh',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: 0,
  marginLeft: 0,
}))
